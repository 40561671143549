import React from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import iso from '../../../img/logo/logo_icon.svg';
import logo from '../../../img/logo/logo_invoice_manager_textonly_negative.svg';
import NavActions from '../Header/NavActions.tsx';

function NavigationLayout({ show, closeMenu, children }) {
  return (
    <div className="navigation-wrapper">
      <div className={`nav-inner ${show ? 'expand' : ''}`}>
        <div className="nav-header">
          <Link to="/" className="logo-wrapper">
            <img src={iso} alt="leadsbox" className="iso" />
            <div className="logo-mask">
              <img src={logo} alt="leadsbox" className="logo" />
            </div>
          </Link>
        </div>
        <div className="nav-actions mobile">
          <NavActions handleCloseMenu={closeMenu} />
        </div>
        <div className="nav-body">{children}</div>
      </div>
    </div>
  );
}

NavigationLayout.propTypes = {
  show: propTypes.bool.isRequired,
  closeMenu: propTypes.func,
  children: propTypes.node.isRequired,
};
export default NavigationLayout;
