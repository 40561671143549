import React from 'react';
import { Form } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import LBOffCanvas from '../UI/LBOffCanvas';

function ChangeEmailRequest() {
  return (
    <LBOffCanvas.Left>
      <LBOffCanvas.Header>Change e-mail</LBOffCanvas.Header>

      <h6 className="mb-3">Send verification e-mail</h6>
      <p className="mb-3">
        Before we can reset your e-mail we need to verify your identity. Check
        your current e-mail in the following minutes for a mail containing the
        link to continue the e-mail change processs.
      </p>
      <p className="mb-4">
        If you don&apos;t have access to the currently set e-mail you may need
        to reach support further assistance.
      </p>
      <Form method="post">
        <Button type="submit">Send e-mail</Button>
      </Form>
    </LBOffCanvas.Left>
  );
}

export default ChangeEmailRequest;
