import React from 'react';
import { useActionData } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

function ActionErrorInformation() {
  const actionErrors = useActionData();
  return (
    actionErrors &&
    actionErrors?.alert && (
      <Alert
        variant={actionErrors?.message !== 'success' ? 'danger' : 'success'}
        className="mt-2 small mx-2"
      >
        <span id="loginError"> {actionErrors?.alert}</span>
      </Alert>
    )
  );
}

export default ActionErrorInformation;
