import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useFetcher, useLocation } from 'react-router-dom';
import ProductCategoriesTable from './ProductCategoriesTable';
import ModalForActions from '../../UI/ModalForActions';

function ProductsCategories() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const { pathname } = useLocation();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteRowID, setDeleteRowID] = useState(null);

  useEffect(() => {
    if (pathname === '/products/categories') setShow(false);
    if (
      pathname.includes('/products/categories/update') ||
      pathname.includes('/products/categories/new')
    )
      setShow(true);
  }, [pathname]);

  const handleClose = () => {
    navigate('/products/categories');
  };

  const handleDeleteModal = (rowID) => {
    setDeleteRowID(rowID);
    setDeleteModal(true);
  };

  const handleDelete = () => {
    fetcher.submit(
      {},
      { method: 'POST', action: `/products/categories/delete/${deleteRowID}` }
    );
  };
  const handleEdit = (rowID, name) => {
    navigate(`/products/categories/update/${rowID}/${name}`);
  };

  return (
    <>
      <ProductCategoriesTable
        handleDelete={handleDeleteModal}
        handleEdit={handleEdit}
      />
      <ModalForActions
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        title="Delete category"
      >
        <div className="">
          <small>
            <p>Are you sure you want to delete this category?</p>
            <p className="mt-2">
              Deleting this category will permanently remove all products within
              it. This action cannot be undone.{' '}
            </p>
            <p className="mt-2">
              Please confirm that you want to proceed, as this will permanently
              erase all associated products.
            </p>
          </small>
          <div className="mt-3 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-danger me-2"
              onClick={() => {
                handleDelete(deleteModal);
                setDeleteModal(false);
              }}
            >
              Yes, delete
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setDeleteModal(false)}
            >
              No
            </button>
          </div>
        </div>
      </ModalForActions>
      <ModalForActions
        show={show}
        onHide={handleClose}
        title={
          pathname === '/products/categories/new'
            ? 'Create new category'
            : 'Update category'
        }
      >
        <Outlet />
      </ModalForActions>
    </>
  );
}

export default ProductsCategories;
