import React from 'react';
import propTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import IconWrapper from '../Customers/CustomersCsv/IconWrapper';

import { Lock } from '../UI/Icons/Icons';

function NavItem({ url, icon, title, onClick, isDisabled }) {
  return (
    <NavLink to={url} className="navItemGrid" onClick={onClick}>
      <IconWrapper
        size={22}
        strokeWidth={1.25}
        stroke={isDisabled ? 'var(--bs-gray-700)' : 'currentColor'}
        className="navigation__nav-item"
      >
        {icon}
      </IconWrapper>
      <span
        className="text small"
        style={{
          textWrap: 'nowrap',
          lineHeight: '1.2',
        }}
      >
        {isDisabled && (
          <span
            style={{
              textWrap: 'nowrap',
              lineHeight: '1',
              display: 'block',
              fontSize: '0.5rem',
              textTransform: 'uppercase',
              color: 'var(--gray)',
              opacity: '0.5',
            }}
          >
            upgrade to unlock
          </span>
        )}
        {title}
      </span>
      {isDisabled && (
        <IconWrapper
          strokeWidth={1.25}
          stroke="var(--bs-warning)"
          className="navigation__nav-item"
        >
          <Lock />
        </IconWrapper>
      )}
    </NavLink>
  );
}
NavItem.propTypes = {
  url: propTypes.string.isRequired,
  icon: propTypes.node.isRequired,
  title: propTypes.string.isRequired,
  onClick: propTypes.func.isRequired,
  isDisabled: propTypes.bool,
};

export default NavItem;
