import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TextTitle from '../UI/text/TextTitle';
import { login } from '../../routes/paths';

function RegisterSuccess() {
  return (
    <>
      <TextTitle>
        <h1>Sucessfull registration!</h1>
        <p>
          Check your mail. To activate the account we must validate the email
          address
        </p>
      </TextTitle>

      <Link to={login} className="mt-5 w-100">
        <Button type="primary" className="w-100">
          Go to login
        </Button>
      </Link>
    </>
  );
}

export default RegisterSuccess;
