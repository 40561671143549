export function ReviewForm() {
  return (
    <path d="m24.99,5.51l5.59,11.33,12.5,1.82c.91.13,1.27,1.25.61,1.89l-9.05,8.82,2.14,12.45c.16.9-.79,1.59-1.61,1.17l-11.18-5.88-11.18,5.88c-.81.43-1.76-.26-1.61-1.17l2.14-12.45-9.05-8.82c-.66-.64-.29-1.76.61-1.89l12.5-1.82,5.59-11.33c.41-.82,1.58-.82,1.98,0Z" />
  );
}

export function Appointments() {
  return (
    <g>
      <g>
        <line x1="14.01" y1="10.38" x2="14.01" y2="1.98" />
        <line x1="30.96" y1="10.38" x2="30.96" y2="1.98" />
      </g>
      <g>
        <path d="m41.17,14.97v16.46c-1.5-1.4-3.51-2.25-5.72-2.25-4.65,0-8.42,3.77-8.42,8.42,0,2.33.94,4.43,2.47,5.95H12.6c-4.86,0-8.8-3.94-8.8-8.79V14.97c0-4.85,3.94-8.79,8.8-8.79h19.78c4.86,0,8.79,3.94,8.79,8.79Z" />
        <line x1="3.8" y1="17.15" x2="41.17" y2="17.15" />
        <g>
          <path d="m43.87,37.6c0,4.65-3.77,8.42-8.42,8.42-2.33,0-4.43-.94-5.95-2.47-1.53-1.52-2.47-3.62-2.47-5.95,0-4.65,3.77-8.42,8.42-8.42,2.21,0,4.22.85,5.72,2.25,1.66,1.53,2.7,3.73,2.7,6.17Z" />
          <polyline points="31.92 38.18 33.88 40.14 38.97 35.05" />
        </g>
      </g>
      <circle cx="15.24" cy="26.22" r=".51" />
      <circle cx="15.24" cy="33" r=".51" />
      <circle cx="22.92" cy="26.22" r=".51" />
    </g>
  );
}

export function Customers() {
  return (
    <g>
      <g>
        <path d="m28.19,5.41c.9-.39,1.89-.6,2.93-.6,4.08,0,7.38,3.31,7.38,7.38s-3.31,7.38-7.38,7.38" />
        <path d="m28.19,22.71c.94-.16,1.92-.25,2.93-.25,6.41,0,11.6,3.41,11.6,7.63,0,2.94-2.54,5.5-6.25,6.77" />
      </g>
      <g>
        <circle cx="18.8" cy="13.63" r="8.89" />
        <ellipse cx="18.8" cy="34.37" rx="13.52" ry="8.89" />
      </g>
    </g>
  );
}

export function Coins() {
  return (
    <g>
      <circle cx="20.08" cy="20.08" r="16.88" />
      <path d="m39.92,25.7c.66.41,1.28.9,1.85,1.47,4.03,4.03,4.03,10.57,0,14.6s-10.57,4.03-14.6,0c-.48-.48-.9-.99-1.27-1.54" />
      <path d="m15.83,22.6c-.4.83-.28,1.81.31,2.52.85,1.02,2.29,1.11,3.63,1.16,1.47.07,3.04.1,4-.96.82-.9.87-2.26.21-3.3-1.66-2.62-5.96-1.55-7.66-4.11-.56-.84-.64-1.92-.07-2.74.86-1.24,2.36-1.34,3.68-1.32,1.35.01,2.78.14,3.75,1.08.67.65,1,1.59.85,2.57" />
      <line x1="20.08" y1="28.8" x2="20.08" y2="26.38" />
      <line x1="20.28" y1="13.86" x2="20.28" y2="11.37" />
    </g>
  );
}

export function OverdueInvoice() {
  return (
    <g>
      <path d="m32.88,4.91v21.43c-4.08.57-7.22,4.09-7.22,8.33,0,.45.04.88.1,1.31l-4.9,3.72c-.98.75-2.35.75-3.34,0l-5.17-3.93-5.17,3.92c-.68.52-1.68.03-1.68-.83V12.64c0-4.26,3.46-7.73,7.73-7.73h19.65Z" />
      <path d="m32.88,4.91h1.89c4.27,0,7.72,3.46,7.72,7.72v3.3c0,2.35-1.9,4.25-4.25,4.25h-5.36V4.91Z" />
      <line x1="11.41" y1="14.22" x2="26.98" y2="14.22" />
      <line x1="11.41" y1="18.97" x2="26.98" y2="18.97" />
      <line x1="11.41" y1="23.72" x2="19.4" y2="23.72" />
      <g>
        <path d="m42.49,34.67c0,4.65-3.76,8.42-8.42,8.42-4.2,0-7.68-3.08-8.31-7.11-.07-.43-.1-.86-.1-1.31,0-4.24,3.14-7.75,7.22-8.33.39-.06.79-.09,1.19-.09,4.65,0,8.42,3.77,8.42,8.42Z" />
        <g>
          <line x1="34.08" y1="30.04" x2="34.08" y2="35.9" />
          <circle cx="34.08" cy="38.29" r=".16" />
        </g>
      </g>
    </g>
  );
}

export function NewInvoice() {
  return (
    <g>
      <path d="M32.88,4.91v21.43c-4.08.57-7.22,4.09-7.22,8.33,0,.45.04.88.1,1.31l-4.9,3.72c-.98.75-2.35.75-3.34,0l-5.17-3.93-5.17,3.92c-.68.52-1.68.03-1.68-.83V12.64c0-4.26,3.46-7.73,7.73-7.73h19.65Z" />
      <path d="M32.88,4.91h1.89c4.27,0,7.72,3.46,7.72,7.72v3.3c0,2.35-1.9,4.25-4.25,4.25h-5.36V4.91Z" />
      <line x1="11.41" y1="14.22" x2="26.98" y2="14.22" />
      <line x1="11.41" y1="18.97" x2="26.98" y2="18.97" />
      <line x1="11.41" y1="23.72" x2="19.4" y2="23.72" />
      <path d="M42.49,34.67c0,4.65-3.76,8.42-8.42,8.42-4.2,0-7.68-3.08-8.31-7.11-.07-.43-.1-.86-.1-1.31,0-4.24,3.14-7.75,7.22-8.33.39-.06.79-.09,1.19-.09,4.65,0,8.42,3.77,8.42,8.42Z" />
      <line x1="29.77" y1="34.67" x2="38.38" y2="34.67" />
      <line x1="34.08" y1="38.98" x2="34.08" y2="30.37" />
    </g>
  );
}

export function NewPayment() {
  return (
    <g>
      <path d="M38.45,27.74c-.66-.16-1.33-.25-2.04-.25-.41,0-.8.03-1.18.09-4.06.57-7.18,4.06-7.18,8.28,0,.45.04.88.1,1.3.1.63.27,1.23.51,1.8-6.64,3.02-14.73,1.8-20.19-3.67-7.04-7.03-7.04-18.44,0-25.48,7.03-7.04,18.44-7.04,25.48,0,4.85,4.85,6.37,11.79,4.52,17.93Z" />
      <path d="M16.66,25.24c-.43.88-.3,1.94.33,2.69.91,1.09,2.45,1.18,3.87,1.24,1.57.07,3.25.1,4.28-1.03.88-.97.93-2.41.22-3.52-1.77-2.79-6.36-1.66-8.17-4.39-.59-.89-.68-2.05-.08-2.93.92-1.33,2.52-1.43,3.93-1.41,1.44.02,2.96.15,4.01,1.16.71.69,1.07,1.7.9,2.74" />
      <line x1="21.2" y1="31.86" x2="21.2" y2="29.27" />
      <line x1="21.41" y1="15.91" x2="21.41" y2="13.24" />
      <path d="M44.78,35.86c0,4.63-3.74,8.38-8.37,8.38-3.52,0-6.54-2.18-7.76-5.27-.24-.57-.41-1.17-.51-1.8-.06-.43-.1-.86-.1-1.3,0-4.22,3.12-7.71,7.18-8.28.39-.06.78-.09,1.18-.09.71,0,1.38.09,2.04.25,3.63.91,6.33,4.2,6.33,8.12Z" />
      <line x1="32.13" y1="35.86" x2="40.7" y2="35.86" />
      <line x1="36.41" y1="40.14" x2="36.41" y2="31.58" />
    </g>
  );
}

export function ContactForm() {
  return (
    <g>
      <g>
        <path d="m42.74,14.83l-4.58,4.58c-1.65-1.18-3.3-2.58-4.89-4.17s-2.99-3.24-4.17-4.89l4.59-4.59c1.43-1.43,5.08-1.02,7.58,1.47,2.5,2.5,2.91,6.16,1.48,7.59Z" />
        <path d="m42.74,14.83l-18.21,18.21c-.08.08-.18.15-.29.18-.64.21-3.18.94-9.35,1.31-.52.03-.95-.4-.92-.92.38-6.17,1.1-8.71,1.31-9.34.04-.11.1-.21.18-.29L33.68,5.77c1.43-1.43,5.08-1.02,7.58,1.47,2.5,2.5,2.91,6.16,1.48,7.59Z" />
      </g>
      <path d="m41.18,24.68v9.53c0,4.89-3.96,8.85-8.85,8.85H13.29c-4.89,0-8.85-3.96-8.85-8.85V15.16c0-4.89,3.96-8.85,8.85-8.85h9.53" />
    </g>
  );
}

export function Reschedule() {
  return (
    <g>
      <circle cx="24" cy="24" r="20.75" />
      <polyline points="24 9.29 24 24 33.92 24" />
    </g>
  );
}

export function Approve() {
  return (
    <g>
      <rect
        x="4.44"
        y="13.78"
        width="10.22"
        height="26.78"
        rx="3.89"
        ry="3.89"
      />
      <path d="m14.67,18.56l8.14-11.62c.75-1.07,1.98-1.71,3.28-1.71h0c2.61,0,4.52,2.45,3.89,4.98l-1.45,5.78c-.33,1.31.66,2.57,2.01,2.57h8.66c2.6,0,4.41,2.59,3.52,5.03l-6.14,16.84c-.61,1.68-2.21,2.79-3.99,2.79h-7.7c-1.3,0-2.56-.4-3.63-1.14l-6.67-4.63" />
    </g>
  );
}

export function Basket() {
  return (
    <g>
      <line x1="17.67" y1="3.89" x2="9.94" y2="11.61" />
      <line x1="30.33" y1="3.89" x2="38.06" y2="11.61" />
      <rect
        x="4.11"
        y="11.61"
        width="39.78"
        height="7.72"
        rx="2.81"
        ry="2.81"
      />
      <path d="m31.02,44.22h-14.04c-3.48,0-6.4-2.6-6.82-6.05l-2.27-18.84h32.22l-2.27,18.84c-.42,3.45-3.34,6.05-6.82,6.05Z" />
      <path d="m31.56,27.66c0,4.08-3.31,7.39-7.39,7.39s-7.39-3.31-7.39-7.39" />
    </g>
  );
}

export function TrashCan() {
  return (
    <g>
      <path d="m43.51,10.95v2.04c0,.83-.67,1.5-1.5,1.5H5.99c-.83,0-1.5-.67-1.5-1.5v-2.04c0-1.95,1.59-3.54,3.54-3.54h31.95c1.95,0,3.54,1.59,3.54,3.54Z" />
      <path d="m40.25,14.49l-1.73,27.84c-.08,1.49-1.32,2.65-2.81,2.65H12.29c-1.49,0-2.73-1.16-2.82-2.65l-1.72-27.84" />
      <path d="m32,7.41v-2.68c0-.95-.77-1.72-1.72-1.72h-12.57c-.95,0-1.72.77-1.72,1.72v2.68" />
      <line x1="16.2" y1="19.48" x2="17.14" y2="37.5" />
      <line x1="24" y1="19.48" x2="24" y2="37.5" />
      <line x1="31.8" y1="19.48" x2="30.86" y2="37.5" />
    </g>
  );
}

export function Edit() {
  return (
    <g>
      <path d="m43.31,10.59l-2.83,2.83c-1.02-.73-2.04-1.59-3.02-2.57s-1.84-2-2.57-3.01l2.83-2.83c.88-.88,3.13-.63,4.68.91s1.79,3.8.91,4.68Z" />
      <path d="m43.31,10.59l-20.85,20.85c-.05.05-.11.09-.18.11-.39.13-1.96.58-5.76.81-.32.02-.58-.25-.56-.56.23-3.8.68-5.37.81-5.76.02-.07.06-.13.11-.18L37.72,5c.88-.88,3.13-.63,4.68.91s1.79,3.8.91,4.68Z" />
      <path d="m40.99,20.36v13.85c0,4.89-3.96,8.85-8.85,8.85H13.09c-4.89,0-8.85-3.96-8.85-8.85V15.16c0-4.89,3.96-8.85,8.85-8.85h14.26" />
    </g>
  );
}

export function EditAlt() {
  return (
    <g>
      <path d="M42.74,14.83l-4.58,4.58c-1.65-1.18-3.3-2.58-4.89-4.17s-2.99-3.24-4.17-4.89l4.59-4.59c1.43-1.43,5.08-1.02,7.58,1.47,2.5,2.5,2.91,6.16,1.48,7.59Z" />
      <path d="M42.74,14.83l-18.21,18.21c-.08.08-.18.15-.29.18-.64.21-3.18.94-9.35,1.31-.52.03-.95-.4-.92-.92.38-6.17,1.1-8.71,1.31-9.34.04-.11.1-.21.18-.29L33.68,5.77c1.43-1.43,5.08-1.02,7.58,1.47,2.5,2.5,2.91,6.16,1.48,7.59Z" />
      <path d="M41.18,24.68v9.53c0,4.89-3.96,8.85-8.85,8.85H13.29c-4.89,0-8.85-3.96-8.85-8.85V15.16c0-4.89,3.96-8.85,8.85-8.85h9.53" />
    </g>
  );
}

export function Carat() {
  return <polyline points="13.24 44.47 34.76 24 13.24 3.53" />;
}

export function CaratBack() {
  return <polyline points="36.76 44.47 15.24 24 36.76 3.53" />;
}

export function DoubleCarat() {
  return (
    <g>
      <polyline points="6.33 44.47 27.86 24 6.33 3.53" />
      <polyline points="19.26 44.47 40.79 24 19.26 3.53" />
    </g>
  );
}

export function Cross() {
  return (
    <g>
      <line x1="4.82" y1="4.82" x2="43.18" y2="43.18" />
      <line x1="4.82" y1="43.18" x2="43.18" y2="4.82" />
    </g>
  );
}

export function Copy() {
  return (
    <g>
      <path d="M29.24,38.57v4.3c0,.62-.51,1.13-1.13,1.13H7.65c-.62,0-1.13-.51-1.13-1.13v-26.72c0-.62.51-1.13,1.13-1.13h6.02" />
      <path d="M41.48,11.94v19.92c0,.62-.51,1.13-1.13,1.13h-20.47c-.62,0-1.13-.51-1.13-1.13V5.13c0-.62.51-1.13,1.13-1.13h13.65l7.94,7.94Z" />
      <polyline points="41.48 11.94 33.54 11.94 33.54 4" />
    </g>
  );
}

export function Pay() {
  return (
    <g>
      <path d="M43.88,24.01c0,10.98-8.89,19.89-19.89,19.89-9.92,0-18.15-7.28-19.63-16.8-.16-1.01-.24-2.03-.24-3.09,0-10.02,7.42-18.33,17.06-19.68.92-.14,1.86-.21,2.81-.21,11,0,19.89,8.91,19.89,19.89Z" />
      <path d="M18.06,27.52c-.56,1.15-.39,2.53.43,3.51,1.19,1.42,3.2,1.54,5.06,1.63,2.06.09,4.25.14,5.59-1.34,1.15-1.26,1.21-3.15.29-4.6-2.31-3.65-8.31-2.17-10.69-5.74-.78-1.17-.89-2.69-.1-3.83,1.2-1.73,3.29-1.86,5.14-1.84,1.89.02,3.88.2,5.24,1.51.93.9,1.4,2.22,1.18,3.59" />
      <line x1="24" y1="36.18" x2="24" y2="32.8" />
      <line x1="24.27" y1="15.33" x2="24.27" y2="11.84" />
    </g>
  );
}

export function Print() {
  return (
    <g>
      <path d="M37.96,10.83v4.64H10.04V5.44c0-.79.64-1.43,1.43-1.43h19.68l6.81,6.82Z" />
      <path d="M37.96,27.01v15.73c0,.69-.56,1.25-1.24,1.25H11.28c-.68,0-1.24-.56-1.24-1.25v-15.73h27.91Z" />
      <path d="M44.35,18.51v13.5c0,1.68-1.36,3.05-3.03,3.05h-3.36v-8.04H10.04v8.04h-3.36c-1.67,0-3.03-1.36-3.03-3.05v-13.5c0-1.67,1.36-3.03,3.03-3.03h34.63c1.67,0,3.03,1.36,3.03,3.03Z" />
      <path d="M31.15,9.4v-5.39l6.81,6.82h-5.38c-.79,0-1.43-.64-1.43-1.43Z" />
      <line x1="10.04" y1="21.45" x2="12.29" y2="21.45" />
      <line x1="8.18" y1="27" x2="10.04" y2="27" />
      <line x1="37.96" y1="27" x2="39.82" y2="27" />
      <line x1="14.18" y1="31.46" x2="33.61" y2="31.46" />
      <line x1="14.18" y1="35.05" x2="33.61" y2="35.05" />
      <line x1="14.18" y1="38.64" x2="33.61" y2="38.64" />
    </g>
  );
}

export function Remove() {
  return (
    <g>
      <path d="M45.18,24c0,11.7-9.48,21.19-21.19,21.19-10.57,0-19.34-7.75-20.91-17.9-.17-1.07-.26-2.17-.26-3.29C2.82,13.32,10.72,4.48,20.99,3.03c.98-.15,1.98-.22,3-.22,11.71,0,21.19,9.49,21.19,21.19Z" />
      <line x1="11.31" y1="24" x2="36.69" y2="24" />
    </g>
  );
}

export function Add() {
  return (
    <g>
      <path d="M45.18,24c0,11.7-9.48,21.19-21.19,21.19-10.57,0-19.34-7.75-20.91-17.9-.17-1.07-.26-2.17-.26-3.29C2.82,13.32,10.72,4.48,20.99,3.03c.98-.15,1.98-.22,3-.22,11.71,0,21.19,9.49,21.19,21.19Z" />
      <line x1="11.31" y1="24" x2="36.69" y2="24" />
      <line x1="24" y1="36.69" x2="24" y2="11.31" />
      <line x1="24" y1="36.69" x2="24" y2="11.31" />
    </g>
  );
}

export function ConvertToInvoice() {
  return (
    <g>
      <path d="M13.5,12.32v-5.95c0-.73.59-1.32,1.32-1.32h17.97l9.29,9.29v27.3c0,.73-.59,1.32-1.32,1.32H14.82c-.73,0-1.32-.59-1.32-1.32v-6.83" />
      <polyline points="41.55 14.34 32.79 14.34 32.79 5.76" />
      <path d="M19.9,14.92c-.19.39-.13.85.15,1.18.4.48,1.07.52,1.7.55.69.03,1.43.05,1.88-.45.38-.42.41-1.06.1-1.55-.78-1.23-2.79-.73-3.59-1.93-.26-.39-.3-.9-.03-1.29.4-.58,1.1-.63,1.73-.62.63,0,1.3.07,1.76.51.31.3.47.74.4,1.2" />
      <line x1="21.9" y1="17.82" x2="21.9" y2="16.69" />
      <line x1="21.99" y1="10.82" x2="21.99" y2="9.66" />
      <line x1="19.87" y1="36.92" x2="32.3" y2="36.92" />
      <line x1="20.12" y1="30.62" x2="37.42" y2="30.62" />
      <line x1="19.87" y1="24" x2="37.17" y2="24" />
      <line x1="3.69" y1="24" x2="15.93" y2="24" />
      <polyline points="10.28 17.6 15.93 24 10.28 30.4" />
    </g>
  );
}

export function Mail() {
  return (
    <g>
      <rect x="2.9" y="10.12" width="42.24" height="28.8" rx="1.91" ry="1.91" />
      <path d="M45.14,14.66c0,1.06-.65,1.97-1.39,2.66-.81.76-1.81,1.33-2.88,1.74l-15.95,6.11c-.58.22-1.22.22-1.8,0l-15.95-6.11c-1.07-.41-2.07-.98-2.88-1.74-.74-.69-1.39-1.6-1.39-2.66" />
    </g>
  );
}

export function Bell() {
  return (
    <g>
      <path d="M39.21,35.68c-1.7.71-5.34,1.99-10.5,2.51-.67.07-1.38.14-2.1.17-.83.05-1.7.07-2.61.07s-1.79-.02-2.62-.07c-.72-.04-1.43-.1-2.1-.17-5.15-.52-8.8-1.8-10.5-2.51-.57-.25-.79-.94-.45-1.48l3.46-5.41c.71-1.09,1.08-2.38,1.08-3.69v-6.62c0-5.25,3.66-9.67,8.58-10.81.32-.07.65-.14.98-.19.51-.07,1.03-.11,1.56-.11s1.04.04,1.55.11c.34.05.67.11.99.19,4.91,1.15,8.56,5.56,8.56,10.81v6.62c0,1.3.37,2.59,1.08,3.69l3.48,5.41c.34.53.12,1.23-.46,1.48Z" />
      <path d="M28.72,38.44c0,2.61-2.11,4.73-4.72,4.73s-4.73-2.12-4.73-4.73c0-.09,0-.16.01-.25.67.07,1.38.14,2.1.17.83.05,1.71.07,2.62.07s1.77-.02,2.61-.07c.72-.04,1.43-.1,2.1-.17.01.09.01.16.01.25Z" />
      <path d="M26.56,7.39c0,.1,0,.2-.01.3-.32-.07-.66-.14-.99-.19-.51-.07-1.02-.11-1.55-.11s-1.05.04-1.56.11c-.34.05-.66.11-.98.19-.01-.1-.01-.2-.01-.3,0-1.41,1.14-2.56,2.56-2.56s2.56,1.14,2.56,2.56Z" />
    </g>
  );
}
export function LocationPin() {
  return (
    <g>
      <circle cx="24" cy="20.25" r="6.54" />
      <path d="M36.81,7.43C29.74.36,18.26.36,11.19,7.43s-7.08,18.55,0,25.63l12.81,12.81,12.81-12.81c7.08-7.08,7.08-18.55,0-25.63Z" />
    </g>
  );
}
export function Smartphone() {
  return (
    <g>
      <rect
        x="10.23"
        y="3.37"
        width="27.55"
        height="41.25"
        rx="3.55"
        ry="3.55"
      />
      <line x1="20.11" y1="40.04" x2="27.89" y2="40.04" />
    </g>
  );
}

export function Calculator() {
  return (
    <g>
      <path d="M32.98,46.28h0c-5.32.23-10.65.23-15.97,0h0c-6.19-.27-11.14-5.22-11.41-11.41h0c-.23-5.32-.23-14.42,0-19.74h0c.27-6.19,5.22-11.14,11.41-11.41h0c5.32-.23,10.65-.23,15.97,0h0c6.19.27,11.14,5.22,11.41,11.41h0c.23,5.32.23,14.42,0,19.74h0c-.27,6.19-5.22,11.14-11.41,11.41Z" />
      <rect
        x="15.54"
        y="12.41"
        width="19.28"
        height="8.38"
        rx="2.78"
        ry="2.78"
      />
      <circle cx="16.98" cy="29.21" r="1" />
      <circle cx="25" cy="29.21" r="1" />
      <circle cx="33.02" cy="29.21" r="1" />
      <circle cx="16.98" cy="36.6" r="1" />
      <circle cx="25" cy="36.6" r="1" />
      <circle cx="33.02" cy="36.6" r="1" />
    </g>
  );
}

export function AppointmentsAlt() {
  return (
    <g>
      <path d="M32.66,45.05h0c-5.77.25-11.55.25-17.33,0h0c-6.71-.29-12.09-5.67-12.38-12.38h0c-.25-5.77-.25-11.55,0-17.33h0c.29-6.71,5.67-12.09,12.38-12.38h0c5.77-.25,11.55-.25,17.33,0h0c6.71.29,12.09,5.67,12.38,12.38h0c.25,5.77.25,11.55,0,17.33h0c-.29,6.71-5.67,12.09-12.38,12.38Z" />
      <polyline points="12.23 17.32 14.16 19.25 18.71 14.7" />
      <line x1="24.73" y1="17.4" x2="36.46" y2="17.4" />
      <polyline points="12.23 31.99 14.16 33.92 18.71 29.36" />
      <line x1="24.73" y1="32.06" x2="36.46" y2="32.06" />
    </g>
  );
}

export function Logout() {
  return (
    <g>
      <path d="M17.68,15.12l.12-2.56c.2-4.44,3.71-8.02,8.14-8.32l2.28-.15c1.62-.11,3.25-.11,4.87,0l2.28.15c4.43.3,7.94,3.88,8.14,8.32l.12,2.56c.26,5.79.27,11.59,0,17.39l-.13,2.94c-.2,4.44-3.7,8.03-8.14,8.33l-2.27.15c-1.62.11-3.25.11-4.87,0l-2.27-.15c-4.44-.3-7.94-3.88-8.14-8.33l-.13-2.94" />
      <line x1="30.29" y1="24.06" x2="4.18" y2="24.06" />
      <polyline points="11.58 31.67 4.18 24.01 11.58 16.35" />
    </g>
  );
}

export function Microphone() {
  return (
    <g>
      <path d="M40.73,17.81v4c0,1-.05,2.01-.23,3-1.41,7.81-8.27,13.69-16.38,13.69h-.27c-8.1,0-14.97-5.88-16.38-13.69-.18-.99-.23-1.99-.23-3v-4" />
      <path d="M24.13,30.91h-.27c-4.77,0-8.72-3.7-9.04-8.45h0c-.25-3.74-.25-7.5,0-11.24h0c.32-4.76,4.27-8.45,9.04-8.45h.27c4.77,0,8.72,3.7,9.04,8.45h0c.25,3.74.25,7.5,0,11.24h0c-.32,4.76-4.27,8.45-9.04,8.45Z" />
      <line x1="23.87" y1="38.49" x2="23.87" y2="45.23" />
      <path d="M27.52,12.16c-.94-.76-2.17-1.22-3.52-1.22s-2.58.46-3.52,1.22" />
      <path d="M26.14,16.59c-.59-.46-1.33-.74-2.14-.74s-1.55.28-2.14.74" />
    </g>
  );
}

export function PriceTag() {
  return (
    <g>
      <circle cx="18.88" cy="18.31" r="5.51" />
      <path d="M2.8,23.32c-.26,2.96.78,5.88,2.85,8.01,3.66,3.77,8.45,8.51,11.17,10.99h0c4.24,3.89,10.76,3.89,15,0h0c3.65-3.34,7.15-6.85,10.49-10.49h0c3.89-4.24,3.89-10.76,0-15h0c-2.48-2.71-7.22-7.51-10.99-11.17-2.13-2.07-5.05-3.11-8.01-2.85l-12.04,1.06c-3.94.35-7.06,3.47-7.41,7.41l-1.06,12.04Z" />
      <line x1="35.87" y1="25.69" x2="26.27" y2="35.29" />
    </g>
  );
}

export function Dashboard() {
  return (
    <g>
      <rect
        x="5.72"
        y="4.89"
        width="15.23"
        height="10.98"
        rx="3.38"
        ry="3.38"
      />
      <rect
        x="5.72"
        y="21.98"
        width="15.23"
        height="21.13"
        rx="3.38"
        ry="3.38"
      />
      <rect
        x="27.05"
        y="32.13"
        width="15.23"
        height="10.98"
        rx="3.38"
        ry="3.38"
        transform="translate(69.33 75.24) rotate(180)"
      />
      <rect
        x="27.05"
        y="4.89"
        width="15.23"
        height="21.13"
        rx="3.38"
        ry="3.38"
        transform="translate(69.33 30.91) rotate(-180)"
      />
    </g>
  );
}

export function Reports() {
  return (
    <g>
      <circle cx="12.51" cy="11.95" r="8.31" />
      <path d="M4.64,23.04v17.61c0,2.11,1.71,3.83,3.83,3.83h28.16" />
      <path d="M16.49,29.91h3.3c1.05,0,1.9.85,1.9,1.9v12.67h-7.1v-12.67c0-1.05.85-1.9,1.9-1.9Z" />
      <path d="M27.51,17.32h3.3c1.05,0,1.9.85,1.9,1.9v25.26h-7.1v-25.26c0-1.05.85-1.9,1.9-1.9Z" />
      <path d="M38.53,20.72h3.3c1.05,0,1.9.85,1.9,1.9v18.03c0,2.11-1.72,3.83-3.83,3.83h-3.27v-21.86c0-1.05.85-1.9,1.9-1.9Z" />
      <path d="M23.48,5.29h16.42c2.11,0,3.83,1.71,3.83,3.83v6.92" />
      <polyline points="12.51 3.64 12.51 11.95 4.2 11.95" />
      <line x1="12.51" y1="11.95" x2="18.38" y2="17.82" />
    </g>
  );
}

export function Vendors() {
  return (
    <g>
      <circle cx="19.03" cy="12.66" r="8.89" />
      <path d="M23.25,41.86c-1.33.29-2.75.44-4.22.44-7.47,0-13.52-3.98-13.52-8.89s6.06-8.89,13.52-8.89c2.8,0,5.41.56,7.57,1.52" />
      <path d="M42.49,35.57c0,4.78-3.87,8.65-8.65,8.65-4.31,0-7.9-3.17-8.54-7.31-.07-.44-.11-.88-.11-1.35,0-4.36,3.23-7.97,7.42-8.56.4-.06.81-.09,1.22-.09,4.78,0,8.65,3.88,8.65,8.65Z" />
      <path d="M31.26,37.1c-.24.5-.17,1.1.19,1.53.52.62,1.39.67,2.2.71.89.04,1.85.06,2.43-.58.5-.55.53-1.37.13-2-1.01-1.59-3.62-.94-4.65-2.5-.34-.51-.39-1.17-.04-1.67.52-.75,1.43-.81,2.24-.8.82,0,1.69.09,2.28.66.41.39.61.96.51,1.56" />
      <line x1="33.84" y1="40.87" x2="33.84" y2="39.4" />
      <line x1="33.96" y1="31.8" x2="33.96" y2="30.28" />
    </g>
  );
}

export function Gear() {
  return (
    <g>
      <circle cx="24" cy="24" r="5.38" />
      <path d="M35.76,15.89l1.1-4.32c.15-.61-.11-1.24-.66-1.56l-6.19-3.57c-.54-.31-1.23-.23-1.68.21l-3.2,3.11c-.63.62-1.64.62-2.28,0l-3.2-3.11c-.45-.44-1.13-.52-1.68-.21l-6.19,3.57c-.54.31-.81.95-.66,1.56l1.1,4.32c.22.86-.29,1.73-1.14,1.97l-4.29,1.21c-.6.17-1.02.72-1.02,1.35v7.14c0,.63.42,1.18,1.02,1.35l4.29,1.21c.85.24,1.36,1.12,1.14,1.97l-1.1,4.32c-.15.61.11,1.24.66,1.56l6.19,3.57c.54.31,1.23.23,1.68-.21l3.2-3.11c.63-.62,1.64-.62,2.28,0l3.2,3.11c.45.44,1.13.52,1.68.21l6.19-3.57c.54-.31.81-.95.66-1.56l-1.1-4.32c-.22-.86.29-1.73,1.14-1.97l4.29-1.21c.6-.17,1.02-.72,1.02-1.35v-7.14c0-.63-.42-1.18-1.02-1.35l-4.29-1.21c-.85-.24-1.36-1.12-1.14-1.97Z" />
    </g>
  );
}

export function Lock() {
  return (
    <g>
      <circle cx="24" cy="28.05" r="2.66" />
      <path d="M40.15,24.83c0-4.73-7.23-8.56-16.15-8.56s-16.15,3.83-16.15,8.56c0,13.64,7.51,17.98,12.28,19.36,1.05.36,2.17.57,3.33.62.34.03.54.03.54.03,0,0,.2,0,.54-.03,1.16-.05,2.27-.26,3.33-.62,4.78-1.38,12.28-5.72,12.28-19.36Z" />
      <path d="M35.19,18.42v-4.06c0-6.18-5.01-11.19-11.19-11.19h0c-6.18,0-11.19,5.01-11.19,11.19v4.06" />
      <line x1="24" y1="30.71" x2="24" y2="35.12" />
    </g>
  );
}

export function Checkmark() {
  return (
    <g>
      <path d="M44.67,22.89c.02.37.03.74.03,1.11,0,11.58-9.39,20.97-20.97,20.97S2.75,35.58,2.75,24,12.14,3.03,23.73,3.03c4.34,0,8.38,1.32,11.73,3.58" />
      <polyline points="11.54 23.58 21.42 33.46 45.25 9.64" />
    </g>
  );
}

export function Download() {
  return (
    <g>
      <path d="M9.12,32.57h-2.24c-1.79,0-3.24,1.45-3.24,3.24v3.8c0,1.79,1.45,3.24,3.24,3.24h34.24c1.79,0,3.24-1.45,3.24-3.24v-3.8c0-1.79-1.45-3.24-3.24-3.24h-2.89" />
      <line x1="24.61" y1="5.15" x2="24.61" y2="36.62" />
      <polyline points="38.11 24.67 24.61 36.58 11.12 24.67" />
      <line x1="37.73" y1="37.71" x2="38.49" y2="37.71" />
    </g>
  );
}

export function CheckTray() {
  return (
    <g>
      <path d="M35.76,34.3h0c0,1.06-.86,1.91-1.91,1.91H14.16c-1.06,0-1.91-.86-1.91-1.91h0c0-1.36-1.1-2.47-2.47-2.47h-4.67c-1.75,0-3.17,1.42-3.17,3.17v6.96c0,1.75,1.42,3.17,3.17,3.17h37.79c1.75,0,3.17-1.42,3.17-3.17v-6.96c0-1.75-1.42-3.17-3.17-3.17h-4.67c-1.36,0-2.47,1.1-2.47,2.47Z" />
      <polyline points="6.96 27.62 6.96 3.88 33.18 3.88" />
      <line x1="41.04" y1="27.62" x2="41.04" y2="11.75" />
      <line x1="11.77" y1="10.02" x2="21.89" y2="10.02" />
      <line x1="11.77" y1="14.19" x2="25.55" y2="14.19" />
      <polygon points="33.18 11.75 33.18 3.88 41.04 11.75 33.18 11.75" />
      <line x1="6.96" y1="21.08" x2="10.3" y2="21.08" />
      <line x1="14.67" y1="21.08" x2="18.01" y2="21.08" />
      <line x1="22.33" y1="21.08" x2="25.67" y2="21.08" />
      <line x1="29.98" y1="21.08" x2="33.32" y2="21.08" />
      <line x1="37.64" y1="21.08" x2="40.97" y2="21.08" />
    </g>
  );
}

export function AddTray() {
  return (
    <g>
      <line x1="16.98" y1="16.37" x2="31.02" y2="16.37" />
      <line x1="24" y1="23.39" x2="24" y2="9.35" />
      <path d="M35.76,34.3h0c0,1.06-.86,1.91-1.91,1.91H14.16c-1.06,0-1.91-.86-1.91-1.91h0c0-1.36-1.1-2.47-2.47-2.47h-4.67c-1.75,0-3.17,1.42-3.17,3.17v6.96c0,1.75,1.42,3.17,3.17,3.17h37.79c1.75,0,3.17-1.42,3.17-3.17v-6.96c0-1.75-1.42-3.17-3.17-3.17h-4.67c-1.36,0-2.47,1.1-2.47,2.47Z" />
      <circle cx="24" cy="16.37" r="13.51" />
    </g>
  );
}

export function RemoveTray() {
  return (
    <g>
      <line x1="16.98" y1="16.37" x2="31.02" y2="16.37" />
      <path d="M35.76,34.3h0c0,1.06-.86,1.91-1.91,1.91H14.16c-1.06,0-1.91-.86-1.91-1.91h0c0-1.36-1.1-2.47-2.47-2.47h-4.67c-1.75,0-3.17,1.42-3.17,3.17v6.96c0,1.75,1.42,3.17,3.17,3.17h37.79c1.75,0,3.17-1.42,3.17-3.17v-6.96c0-1.75-1.42-3.17-3.17-3.17h-4.67c-1.36,0-2.47,1.1-2.47,2.47Z" />
      <circle cx="24" cy="16.37" r="13.51" />
    </g>
  );
}

export function Check() {
  return (
    <g>
      <path d="M12.18,32.14c.06.16.15.31.26.45.55.66,1.48.72,2.35.75.95.04,1.97.06,2.59-.62.53-.59.56-1.46.13-2.14-1.07-1.69-3.86-1.01-4.96-2.66-.36-.54-.41-1.25-.05-1.78.56-.8,1.53-.86,2.39-.86.88,0,1.8.09,2.43.7.22.21.38.47.47.75" />
      <line x1="14.99" y1="34.98" x2="14.99" y2="33.41" />
      <line x1="15.11" y1="25.3" x2="15.11" y2="23.69" />
      <line x1="36.93" y1="25.22" x2="42.23" y2="25.22" />
      <line x1="23.49" y1="33.43" x2="36.93" y2="33.43" />
      <line x1="23.49" y1="29.33" x2="42.23" y2="29.33" />
      <path d="M42.69,18.78h1.98c1,0,1.82.81,1.82,1.82v17.47c0,1-.81,1.82-1.82,1.82H9.25c-1,0-1.82-.81-1.82-1.82v-17.47c0-1,.81-1.82,1.82-1.82h9.03" />
      <path d="M45.7,9.71l-3.58,3.58c-1.29-.92-2.58-2.02-3.82-3.26s-2.34-2.53-3.26-3.82l3.58-3.58c1.12-1.12,3.97-.8,5.92,1.15,1.96,1.96,2.27,4.81,1.16,5.93Z" />
      <path d="M45.7,9.71l-14.23,14.23c-.06.06-.14.11-.23.14-.5.17-2.48.73-7.3,1.02-.4.02-.74-.31-.72-.72.29-4.82.86-6.8,1.02-7.3.03-.09.08-.16.14-.23l14.23-14.23c1.12-1.12,3.97-.8,5.92,1.15,1.96,1.96,2.27,4.81,1.16,5.93Z" />
      <path d="M12.96,18.78c0-3.05-2.47-5.53-5.53-5.53s-5.53,2.47-5.53,5.53v25.4c0,.95.77,1.72,1.72,1.72h36.89c.95,0,1.72-.77,1.72-1.72v-4.29" />
    </g>
  );
}

export function Info() {
  return (
    <g>
      <path d="M19.29,46.78c2.9.52,6.41-.59,8.98-2.06.71-.4,1.74-1.45.36-1.79-.95-.24-2.4-.14-3.22-.67-2.66-1.68-1.67-6.16-.84-9.41,1.13-5.15,6.79-12.91,2.44-17.64-2.85-2.76-9.23-2.13-12.13.36-.98,1.61,2.32,1.73,3.07,2.67.35.31.63.69.79,1.13.49,1.32-.05,2.89-.53,4.18-2.05,6.07-7.9,20.49.91,23.19l.18.04Z" />
      <circle cx="25.18" cy="6.33" r="5.24" />
    </g>
  );
}
export function HideEye() {
  return (
    <g>
      <path d="M34.79,12.73c-3.04-1.57-6.64-2.65-10.79-2.65C9.16,10.08,1.29,24,1.29,24c0,0,3.95,6.99,11.53,11.06" />
      <path d="M19.03,37.38c1.56.34,3.21.54,4.97.54,14.84,0,22.71-13.92,22.71-13.92,0,0-2.32-4.1-6.8-7.84" />
      <path d="M32.1,24c0,4.39-3.5,7.97-7.86,8.09" />
      <path d="M18.18,29.62c-1.41-1.46-2.27-3.44-2.27-5.62,0-4.47,3.62-8.1,8.1-8.1,2.14,0,4.08.83,5.52,2.18" />
      <line x1="6.41" y1="41.59" x2="40.53" y2="6.89" />
    </g>
  );
}

export function ShowEye() {
  return (
    <g>
      <path d="M24,10.08C9.16,10.08,1.29,24,1.29,24c0,0,7.87,13.92,22.71,13.92s22.71-13.92,22.71-13.92c0,0-7.87-13.92-22.71-13.92Z" />
      <circle cx="24" cy="24" r="8.1" />
    </g>
  );
}

export function Help() {
  return (
    <g>
      <circle cx="24" cy="24.01" r="21.9" />
      <path d="M15.89,18.02c0-4.56,3.76-8.23,8.34-8.11,4.23.12,7.76,3.65,7.88,7.88.1,3.76-2.36,6.97-5.76,8-1.39.42-2.34,1.68-2.34,3.12v1.91" />
      <circle cx="24" cy="36.93" r=".25" />
    </g>
  );
}
