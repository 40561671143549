import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TextTitle from '../UI/text/TextTitle';

function ResendEmail() {
  return (
    <>
      <TextTitle>
        <h1>Resend verification email</h1>
        <p className="lb-text__subtitle mt-0">E-mail validation</p>
      </TextTitle>
      <div>
        <p>We sent a new email with a fresh token.</p>
      </div>
      <div className="mt-5">
        <Button as={Link} to="/">
          Go to Dashboard
        </Button>
      </div>
    </>
  );
}

export default ResendEmail;
