import { useSearchParams, useSubmit } from 'react-router-dom';
import * as Yup from 'yup';
import GenericForm from '../Forms/builders/GenericForm';
import LBOffCanvas from '../UI/LBOffCanvas';
import ActionErrorInformation from '../../API/ActionErrorInformation';

function VerifyEmailOTP() {
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  const fields = [
    {
      name: 'otp',
      label: 'One Time Passkey',
      initialValue: '',
      type: Yup.string()
        .matches(/^[0-9]{6}$/, 'Must be exactly 6 digits')
        .required('Please input the code you received'),
      formType: 'numeric',
      required: true,
      autocomplete: 'off',
    },
  ];
  const submit = useSubmit();
  const submitAction = (values) =>
    submit({ ...values, token }, { method: 'post' });

  return (
    <LBOffCanvas.Left>
      <LBOffCanvas.Header>Change e-mail</LBOffCanvas.Header>
      <p className="mb-4">
        After submitting your new email, you will receive an e-mail in your new
        e-mail address to confirm and verify this change.
      </p>
      <ActionErrorInformation />
      <GenericForm
        fields={fields}
        submitAction={submitAction}
        formId="verifyEmailOTPForm"
        submitLabel="Submit Code"
      />
    </LBOffCanvas.Left>
  );
}

export default VerifyEmailOTP;
