/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link, redirect, useSubmit } from 'react-router-dom';
import * as Yup from 'yup';
import TextTitle from '../UI/text/TextTitle';
import { login } from '../../routes/paths';
import ActionErrorInformation from '../../API/ActionErrorInformation';
import GenericForm from '../Forms/builders/GenericForm.tsx';

function Register() {
  // Submit action
  const submit = useSubmit();
  const submitAction = (values) => {
    submit(values, {
      method: 'post',
      action: '/auth/register',
    });
  };

  // Prevent the use of the component outside of test/dev env
  const isTestingEnv = process.env.REACT_APP_IS_TESTING;
  const isDevEnv = process.env.NODE_ENV === 'development';
  if (!isDevEnv && !isTestingEnv) redirect('/');

  // Formik
  const fields = [
    {
      name: 'first_name',
      label: 'First name',
      placeholder: 'First name',
      initialValue: '',
      type: Yup.string().required('Please enter a name'),
      required: true,
    },
    {
      name: 'email',
      label: 'Email',
      placeholder: 'name@email.com',
      initialValue: '',
      type: Yup.string().email().required('Please add your email'),
      formType: 'email',
      required: true,
      autocomplete: 'new-password',
    },
    {
      name: 'password',
      label: 'Password',
      placeholder: 'password',
      initialValue: '',
      type: Yup.string().strict(true).required('Password is required!'),
      formType: 'password',
      required: true,
    },
    {
      name: 'tier',
      label: 'Select subscription tier',
      initialValue: 'tier1',
      type: Yup.string()
        .oneOf(['tier1', 'tier2', 'tier3'])
        .required('Please select a sub tier'),
      formType: 'select',
      required: true,
      isClearable: false,
      options: [
        {
          value: 'tier1',
          label: 'Tier 1',
        },
        {
          value: 'tier2',
          label: 'Tier 2',
        },
        {
          value: 'tier3',
          label: 'Tier 3',
        },
      ],
    },
  ];

  return (
    <>
      <TextTitle>
        <h1>Register</h1>
        <p className="small">
          This will create a{' '}
          <strong style={{ fontSize: 'revert', display: 'inline' }}>
            {isDevEnv ? 'development' : 'testing'}
          </strong>{' '}
          account that will only work on that environment. Some features only
          work on production and the DB&apos;s don&apos;t share information,
          keep in mind while performing tests.
        </p>
      </TextTitle>
      <ActionErrorInformation />

      <GenericForm
        fields={fields}
        submitAction={submitAction}
        formId="registerForm"
        submitLabel="Register"
      />

      <div className="mt-5 d-flex">
        <p className="small">Already have an account? &nbsp;</p>
        <Link to={login} className="small">
          Log In
        </Link>
      </div>
    </>
  );
}

export default Register;
