/* eslint react/prop-types: 0 */
import React from 'react';
import { Button } from 'react-bootstrap';

function FormSubmit({
  id,
  isSubmitting,
  // dirty,
  // isValid,
  label = 'Save',
  variant = 'primary',
}) {
  const classes = 'ms-auto btn w-100 mt-2';
  const disabledClasses = `${classes} disabled lb-btn-disabled`;

  return (
    <div className=" d-flex justify-content-end">
      <Button
        variant={variant}
        className={isSubmitting ? disabledClasses : classes}
        type="submit"
        disabled={isSubmitting}
        form={id}
      >
        {isSubmitting ? 'Loading...' : label}
      </Button>
    </div>
  );
}

export default FormSubmit;
