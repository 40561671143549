/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSubmit, Outlet } from 'react-router-dom';
import { number, string } from 'yup';
import useFormikTemplate from '../../Forms/hooks/useFormikTemplate';
import CreateForm from '../../Forms/CreateForm';
import FormWrapper from '../../Forms/builders/FormWrapper';
import ReportDisplay from '../ReportDisplay';

// Utils
import getRange from '../utils/getRange';
import getDateFields from '../utils/rangeFields';

// Constants
import { SALES_TOGGLE } from '../constants/toggleOptions';
import RANGE_OPTIONS from '../constants/rangeOptions';

// SCSS
import '../Reports.scss';
import useFetcherOnce from '../../Invoices/useFetcherOnce';

function Sales() {
  const submit = useSubmit();
  const customerFetcher = useFetcherOnce('/customers');
  const itemsFetcher = useFetcherOnce('/products');
  const [dateRange, setDateRange] = React.useState('customRange');
  const [displayValues, setDisplayValues] = React.useState({});
  const [reportType, setReportType] = React.useState('sales');
  const [selectedCategory, setSelectedCategory] = React.useState(0);

  const handleCategorySelection = (category) => {
    formik.setFieldValue('combo_product_id', '');
    if (!category) return;

    setSelectedCategory(category.family_id);
  };

  const customers = customerFetcher?.data?.customers?.map((customer) => ({
    value: customer.id,
    label: customer.name,
  })) || [{ value: 'Loading', label: 'Loading' }];

  const productsAndServices = itemsFetcher?.data?.products.map((item) => ({
    value: item.id,
    label: item.name,
    type: item.type,
  })) || [{ value: 'Loading', label: 'Loading' }];

  const reportTypeField = {
    name: 'report_type',
    label: 'Type',
    initialValue: SALES_TOGGLE[0].value,
    formType: 'toggleGroup',
    options: SALES_TOGGLE,
    type: string().required(),
    required: true,
    className: 'flex__span-12',
    change: (e) => {
      setReportType(e.target.value);
      formik.setFieldValue('product_filter', 'all');
      return null;
    },
  };

  const customerFilterField = {
    name: 'customer_filter',
    label: 'Filter Customer',
    initialValue: 'all',
    formType: 'select',
    options: [{ value: 'all', label: 'All' }, ...customers],
    type: string().required(),
    isClearable: false,
    required: true,
    className: 'flex__span-12',
  };

  // This serves as "Service" field, sorry for the naming hehe
  const productsFilterField = {
    name: 'product_filter',
    label: 'Filter Product',
    initialValue: 'all',
    formType: 'select',
    options: [
      { value: 'all', label: 'All' },
      ...productsAndServices.filter((item) =>
        reportType === 'sales_by_product'
          ? item.type === 'Product'
          : item.type === 'Service'
      ),
    ],
    type: string().required(),
    isClearable: false,
    required: true,
    className: 'flex__span-12',
  };

  const comboFields = [
    {
      name: 'combo_category_id',
      label: 'Category',
      formType: 'productCategoryCombo',
      initialValue: '',
      type: number().nullable(),
      className: 'flex__span-4',
      change: (e) => handleCategorySelection(e),
    },
    {
      name: 'combo_product_id',
      label: 'Product or service',
      initialValue: '',
      type: string().nullable(),
      formType: 'productCombo',
      categoryId: selectedCategory,
      className: 'flex__span-4',
      disabled: !selectedCategory,
      // change: (e) => handleProductSelection(e),
    },
  ];

  const rangeFields = [
    {
      name: 'select_range',
      label: 'Range',
      formType: 'select',
      initialValue: RANGE_OPTIONS[0].value,
      options: RANGE_OPTIONS,
      type: string(),
      isClearable: false,
      className: 'flex__span-3',
      change: async (e) => {
        setDateRange(e);
        if (e === 'customRange') return null;
        const range = getRange(e);
        await formik.setFieldValue('date_from', range.startDate, true);
        await formik.setFieldValue('date_to', range.endDate, true);
        return null;
      },
    },
    ...getDateFields({ dateRange, reportType }),
  ];

  const submitAction = (values) => {
    setDisplayValues((prev) => ({
      ...prev,
      dateTo: values.date_to,
      dateFrom: values.date_from,
      reportType: values.report_type,
    }));

    submit(
      {
        to: values.date_to,
        from: values.date_from,
        customerFilter: values.customer_filter,
        productFilter: values.product_filter,
        comboCategory: values.combo_category_id,
        comboProduct: values.combo_product_id,
      },
      {
        method: 'POST',
        action: values.report_type.replaceAll('_', '-'),
        encType: 'application/json',
      }
    );
  };

  const [formik] = useFormikTemplate({
    initial: [
      ...rangeFields,
      reportTypeField,
      customerFilterField,
      productsFilterField,
      ...comboFields,
    ],
    yupValues: [
      ...rangeFields,
      reportTypeField,
      customerFilterField,
      productsFilterField,
      ...comboFields,
    ],
    submitAction,
    enableReinitialize: false,
  });

  return (
    <div className="reports__wrapper">
      <div className="reports__wrapper__sticky-container">
        <div className="reports__wrapper__action-wrapper  rounded shadow-sm p-4">
          <FormWrapper
            id="salesForm"
            label="Run Report"
            handleSubmit={formik.handleSubmit}
            isSubmitting={formik.isSubmitting}
            dirty={
              reportType === 'products_and_services_list' ? false : formik.dirty
            }
            isValid={
              reportType === 'products_and_services_list'
                ? true
                : formik.isValid
            }
            portalId="report-submit"
            className="flex__form-wrapper"
          >
            <div className="form-two">
              <CreateForm structure={[reportTypeField]} {...formik} />

              {formik?.values?.report_type === 'sales_by_customer' && (
                <CreateForm structure={[customerFilterField]} {...formik} />
              )}

              {(formik?.values?.report_type === 'sales_by_product' ||
                formik?.values?.report_type === 'sales_by_service') && (
                <CreateForm structure={[productsFilterField]} {...formik} />
              )}

              {formik?.values?.report_type === 'products_and_services_list' && (
                <CreateForm structure={comboFields} {...formik} />
              )}

              {formik?.values?.report_type !== 'products_and_services_list' && (
                <CreateForm structure={rangeFields} {...formik} />
              )}

              <div
                id="report-submit"
                className={
                  formik?.values?.report_type === 'products_and_services_list'
                    ? 'flex__span-4'
                    : 'flex__span-3'
                }
                style={{ paddingTop: '1.25rem' }}
              />
            </div>
          </FormWrapper>
        </div>
      </div>
      <ReportDisplay reportValue={displayValues.reportType}>
        <Outlet
          context={{
            reportValue: displayValues.reportType,
            dateTo: displayValues.dateTo,
            dateFrom: displayValues.dateFrom,
          }}
        />
      </ReportDisplay>
    </div>
  );
}

export default Sales;
