import { I_Checker, I_OptionalFields, I_UserRoot } from './propTypes';

const optionalFields: I_OptionalFields[] = [
  { key: 'logo', label: 'Company logo', link: '/settings' },
  { key: 'address', label: 'State, country and zip', link: '/settings' },
  { key: 'phone', label: 'Phone number', link: '/settings' },
  { key: 'email', label: 'e-mail address', link: '/settings' },
];
export const isDataAvailable = (rootData: I_UserRoot): I_Checker => ({
  mandatory: !!rootData?.user_company,
  address: Boolean(
    rootData?.user_country && rootData?.user_state && rootData?.user_zip
  ),
  slogan: Boolean(rootData?.company_slogan),
  logo: Boolean(rootData?.company_logo_base64),
  phone: Boolean(rootData?.user_phone),
  email: Boolean(rootData?.user_email),
});

export const getIncompleteFields = (checker: I_Checker): I_OptionalFields[] => {
  const incompleteData = optionalFields
    .filter(
      (field) => field.key in checker && !checker[field.key as keyof I_Checker]
    )
    .map((field) => ({
      label: field.label,
      key: field.key,
      link: field.link,
    }));

  return incompleteData;
};

export interface I_field {
  // eslint-disable-next-line no-restricted-globals
  name: string;
  label: string;
  required: boolean;
  initialValue: string;
  type: any;
  formType?: string;
  options?: any[];
}
