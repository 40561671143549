/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useSubmit } from 'react-router-dom';
import { string } from 'yup';
import createFileForm from '../Forms/helpers/createFileForm';
import useFormikTemplate from '../Forms/hooks/useFormikTemplate';
import FormWrapper from '../Forms/builders/FormWrapper';
import LBFile from '../Forms/FileDragAndDrop/File';
import CreateForm from '../Forms/CreateForm';
import SECTION_OPTIONS from './data';

function SupportPage() {
  const generalFields = [
    {
      formGroup: 'Support Form',
      showTitle: false,
      groupChildren: [
        {
          name: 'where',
          formType: 'select',
          label: 'Where did you encounter the issue?',
          type: string().required(),
          initialValue: 'general',
          required: true,
          className: 'flex__span-12',
          isClearable: false,
          options: [...SECTION_OPTIONS],
        },
        {
          name: 'description',
          formType: 'textarea',
          label: 'Describe the issue',
          maxLength: 1000,
          type: string().max(1000).required(),
          initialValue: '',
          required: true,
          className: 'flex__span-12',
        },
        {
          name: 'want_to_contacted',
          formType: 'checkbox',
          label: 'Contact me on updates with the issue',
          type: string().required(),
          initialValue: true,
          required: true,
          className: 'flex__span-12',
        },
      ],
    },
  ];

  const fileField = [
    {
      name: 'image',
      label: 'Upload Image (optional)',
      type: string(),
      initialValue: '',
    },
  ];

  // FILE COMPONENT STATE AND FUNCTIONS START
  // Track/save the file in this component cause formik is tracking but not storing on state
  const [formData, setFormData] = React.useState({});

  const fields = [...generalFields, ...fileField];
  const reactRouterSubmit = useSubmit();
  const submitAction = async (values) => {
    const nextValues = createFileForm(values, formData);
    reactRouterSubmit(nextValues, {
      method: 'post',
      encType: 'multipart/form-data',
    });
  };

  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
  });

  const deleteFile = () => {
    // We don't really need this since we will never access an existing
    // file here, this is used to delete the file from the server storage
    // on other places we use the LBFile component
    return null;
  };

  return (
    <Card>
      <Container fluid>
        <Row className="gap-5 px-2 py-md-3 px-md-4">
          <Col lg={6}>
            <h2 className="mb-1">Found a problem?</h2>
            <h3 className="mb-4 text-muted">We&apos;re here to help!</h3>
            <p className="mb-3">
              We’re sorry for any inconvenience you&apos;ve experienced while
              using our app. To help us assist you as quickly as possible,
              please provide the following details about the issue:
            </p>
            <ul className="small">
              <li className="mb-2">
                <strong>Where it happened:</strong> Let us know which section or
                feature of the app the issue occurred in.
              </li>
              <li className="mb-2">
                <strong>Short description:</strong> Briefly describe the issue
                you&apos;re experiencing.
              </li>
              <li className="mb-2">
                <strong>Would you like us to contact you?</strong> Let us know
                if you&apos;d like to receive updates or further assistance.
              </li>
              <li className="mb-2">
                <strong>Optional image:</strong> If you have a screenshot or
                photo related to the issue, feel free to attach it! It may help
                speed up the solution.
              </li>
            </ul>
            <p className="mb-3">
              Our team will review your submission and respond within 24 hours.
              While we strive to address every issue promptly, please note that
              this response timeframe doesn&apos;t guarantee a resolution within
              24 hours. Rest assured, we&apos;re dedicated to finding a solution
              as quickly as possible.
            </p>
            <p>Thank you for helping us improve your experience!</p>
          </Col>
          <Col>
            <FormWrapper
              id="support-form"
              handleSubmit={formik.handleSubmit}
              isSubmitting={formik.isSubmitting}
              dirty={formik.dirty}
              isValid={formik.isValid}
              label="Send support e-mail"
              className="flex__form-wrapper"
            >
              <CreateForm structure={generalFields} {...formik} />
              <LBFile
                value={formik.values.image}
                setFieldValue={formik.setFieldValue}
                setFormData={setFormData}
                deleteFile={deleteFile}
                name={fileField[0].name}
                label={fileField[0].label}
                resize={{ type: 'none', x: 0, y: 0 }}
              />
            </FormWrapper>
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

export default SupportPage;
