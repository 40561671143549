/* eslint-disable prefer-template */

export default function formatAddress(addressObject, secondLine = false) {
  const {
    street_address_1: street1,
    street_address_2: street2,
    city,
    state,
    zipcode,
    country,
  } = addressObject;

  // This looks horrible but if you format it "pretty" the
  // template literal takes the spaces and it ends up looking weird
  // on the details page
  const address = `${street1 || ''}${street2 && secondLine ? ', ' + street2 : ''}${
    city ? ', ' + city : ''
  }${state ? ', ' + state : ''}${zipcode ? ', ' + zipcode : ''}${
    country ? ', ' + country : ''
  }`;

  return address;
}
