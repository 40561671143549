// Import components
import InvoiceModalContent from '../../components/Invoices/InvoiceModalContent';
import InvoiceNewInitializer from '../../components/Invoices/InvoiceNewInitializer';
import Invoices from '../../components/Invoices/Invoices';
import invoicesListAndModal from './invoicesModal';

// Loaders and actions
import {
  createInvoiceAction,
  updateInvoiceAction,
  invoiceLoader,
  invoicesLoader,
  nextInvoiceNumberLoader,
} from './invoicesLA';
import CustomersNewModal from '../../components/Customers/CustomersNewModal';
import { createCustomerAction } from '../customers/customersLA';
import getHeaderTooltip from '../../components/HelpTooltip/getHeaderTooltip';

export const customerModalRoute = () => ({
  path: 'new-customer',
  element: <CustomersNewModal />,
  action: ({ request, params }) =>
    createCustomerAction({ request, params, hardRedirect: '..' }),
});

const createInvoice = (routeId) => {
  return [
    {
      path: '',
      element: <Invoices />,
      loader: invoicesLoader,
      handle: {
        linkTo: {
          label: 'New',
          to: 'new',
          toolTip: (matches) => getHeaderTooltip(matches, 'invoice'),
        },
      },
      children: [
        {
          path: ':invoiceId',
          loader: invoiceLoader,
          id: routeId,
          handle: {
            crumb: (match) =>
              `${
                match?.data?.data?.invoice?.number || 'Invoice number missing'
              }`,
          },
          children: [
            {
              path: '',
              element: <InvoiceModalContent />,
              children: [...invoicesListAndModal],
            },
          ],
          errorElement: <p>Sorry, an unexpected error has occurred.</p>,
        },
      ],
    },
    { path: 'nextInvoiceNumber', loader: nextInvoiceNumberLoader },
    {
      path: 'new',
      element: <InvoiceNewInitializer />,
      action: createInvoiceAction,
      handle: {
        crumb: () => 'New',
        linkTo: {
          noShow: true,
        },
      },
      children: [customerModalRoute()],
    },

    {
      path: ':invoiceId',
      loader: invoiceLoader,
      handle: {
        crumb: (match) =>
          `${match?.data?.data?.invoice?.number || 'Invoice number missing'}`,
      },
      children: [
        {
          path: 'edit',
          element: <InvoiceNewInitializer />,
          action: updateInvoiceAction,
          loader: invoiceLoader,
          handle: {
            crumb: (response) =>
              `Edit ${response?.data?.data?.invoice?.number || 'invoice'}`,
            linkTo: {
              noShow: true,
            },
          },
          children: [customerModalRoute()],
        },

        {
          path: 'copy',
          element: <InvoiceNewInitializer copy />,
          action: ({ request, params }) =>
            createInvoiceAction({ request, params, copy: true }),
          loader: invoiceLoader,
          handle: {
            crumb: (match) =>
              `Copy ${match?.data?.data?.invoice?.number || 'Invoice'}`,
            linkTo: {
              noShow: true,
            },
          },
          children: [customerModalRoute()],
        },
      ],
    },
  ];
};

export default createInvoice;
