import React from 'react';
import * as Yup from 'yup';
import { useSearchParams, useSubmit } from 'react-router-dom';
import TextTitle from '../UI/text/TextTitle';
import ActionErrorInformation from '../../API/ActionErrorInformation';
import GenericForm from '../Forms/builders/GenericForm';

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const userId = searchParams.get('uid');

  const fields = [
    {
      name: 'new_password',
      label: 'New Password',
      initialValue: '',
      type: Yup.string().strict(true).required('A new password is required!'),
      formType: 'password',
      required: true,
      autocomplete: 'new-password',
    },
    {
      name: 'new_password_confirm',
      label: 'Confirm New Password',
      initialValue: '',
      type: Yup.string()
        .strict(true)
        .required('Please confirm your new password!')
        .oneOf([Yup.ref('new_password'), null], 'Passwords must match!'),
      formType: 'password',
      required: true,
      autocomplete: 'false',
    },
  ];
  const submit = useSubmit();
  const submitAction = (values) =>
    submit({ ...values, token, uid: Number(userId) }, { method: 'post' });

  return (
    <>
      <TextTitle>
        <h1>Enter a new password</h1>
      </TextTitle>
      <ActionErrorInformation />
      <GenericForm
        fields={fields}
        submitAction={submitAction}
        formId="resetPasswordForm"
        submitLabel="Reset password"
      />
    </>
  );
}

export default ResetPassword;
