import { useSearchParams, useSubmit } from 'react-router-dom';
import * as Yup from 'yup';
import GenericForm from '../Forms/builders/GenericForm';
import LBOffCanvas from '../UI/LBOffCanvas';
import ActionErrorInformation from '../../API/ActionErrorInformation';

function ChangeEmailForm() {
  const [searchParams] = useSearchParams();

  const userId = searchParams.get('uid');
  const token = searchParams.get('token');

  const fields = [
    {
      name: 'new_email',
      label: 'New E-mail',
      initialValue: '',
      type: Yup.string().strict(true).required('A new e-mail is required!'),
      formType: 'email',
      required: true,
      autocomplete: 'email',
    },
    {
      name: 'new_email_confirm',
      label: 'Confirm New E-mail',
      initialValue: '',
      type: Yup.string()
        .strict(true)
        .required('Please confirm your new password!')
        .oneOf([Yup.ref('new_email'), null], 'E-mails must match!'),
      formType: 'email',
      required: true,
      autocomplete: 'off',
    },
  ];
  const submit = useSubmit();
  const submitAction = (values) =>
    submit({ ...values, uid: Number(userId), token }, { method: 'post' });

  return (
    <LBOffCanvas.Left>
      <LBOffCanvas.Header>Change e-mail</LBOffCanvas.Header>
      <p className="mb-4">
        After submitting your new email, you will receive an e-mail in your new
        e-mail address to confirm and verify this change.
      </p>
      <ActionErrorInformation />
      <GenericForm
        fields={fields}
        submitAction={submitAction}
        formId="changeEmailForm"
        submitLabel="Change e-mail"
      />
    </LBOffCanvas.Left>
  );
}

export default ChangeEmailForm;
