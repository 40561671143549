/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useRef, useState, useMemo, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import useContainerDimensions from './useContainerDimensions';
import IconButton from '../../UI/IconButton/IconButton.tsx';
import { Carat, CaratBack } from '../../UI/Icons/Icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function LoadingState() {
  return (
    <div
      style={{
        display: 'grid',
        placeContent: 'center',
        backgroundColor: 'var(--bs-gray-100)',
        width: '100%',
        height: '100%',
      }}
    >
      <Spinner />
    </div>
  );
}

function PDFViewer({ title, className, instance, innerRef, ...props }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const containerRef = useRef(null);
  const dimensions = useContainerDimensions(containerRef);

  // React.useEffect(() => {
  //   pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  //     '/pdf.worker.mjs',
  //     process.env.PUBLIC_URL
  //   ).toString();
  // }, []);

  // Memoize the PDF file to prevent unnecessary renders
  const memoizedPdfFile = useMemo(() => instance?.url, [instance?.url]);

  // Reset page number and loading state when PDF changes
  useEffect(() => {
    setPageNumber(1);
  }, [memoizedPdfFile]);

  const onDocumentLoadSuccess = (pdf) => {
    setNumPages(pdf.numPages);
  };

  const nextPage = () => {
    if (pageNumber < numPages) setPageNumber((prev) => prev + 1);
  };
  const prevPage = () => {
    if (pageNumber > 1) setPageNumber((prev) => prev - 1);
  };

  if (!memoizedPdfFile || instance?.loading) {
    return <LoadingState />;
  }

  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        height: 'auto',
        border: '1px solid var(--bs-gray-200)',
        borderRadius: 8,
        overflow: 'hidden',
      }}
    >
      <iframe
        title={title}
        ref={innerRef}
        src={memoizedPdfFile}
        style={{ display: 'none' }}
      />
      <Document
        file={memoizedPdfFile}
        loading={
          <div
            style={{
              display: 'grid',
              placeContent: 'center',
              backgroundColor: 'var(--bs-gray-100)',
              width: '100%',
              height: dimensions.height,
            }}
          >
            <Spinner />
          </div>
        }
        onLoadSuccess={onDocumentLoadSuccess}
        className={className}
        {...props}
      >
        {numPages > 0 && (
          <Page
            width={dimensions.width}
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        )}

        <div
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            borderRadius: 8,
            gap: 8,
            left: '50%',
            bottom: 16,
            transform: 'translateX(-50%)',
            backgroundColor: 'var(--bs-white)',
            border: '1px solid var(--bs-gray-300)',
          }}
        >
          <IconButton onClick={prevPage}>
            <CaratBack />
          </IconButton>
          <p>
            {pageNumber} / {numPages}
          </p>
          <IconButton onClick={nextPage}>
            <Carat />
          </IconButton>
        </div>
      </Document>
    </div>
  );
}

export default PDFViewer;
