/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint react/prop-types: 0 */
/* eslint-disable no-use-before-define */

import React, { useState } from 'react';
import {
  Link,
  Outlet,
  useResolvedPath,
  useSearchParams,
  useSubmit,
} from 'react-router-dom';
import * as Yup from 'yup';
import SelectedProducts from '../ProductsAndServices/SelectedProducts/SelectedProducts';
import CreateForm from '../Forms/CreateForm/CreateForm';
import FormWrapper from '../Forms/builders/FormWrapper';
import useFormikTemplate from '../Forms/hooks/useFormikTemplate';
import ActionErrorInformation from '../../API/ActionErrorInformation';
import BlockerModal from '../Appointments/BlockerModal';
import useModal from '../UI/LBOffCanvas/useModal';
import LBOffCanvas from '../UI/LBOffCanvas';
import CustomerInfoForm from '../Invoices/CustomerInfoForm';

export default function EstimateNew({
  estimate: { items, id, notes, dates },
  estimate,
  customer,
  copy,
}) {
  // Modal config
  const { pathname: currentPath } = useResolvedPath('.');

  let modalRootPath = 'new';
  if (estimate?.id) modalRootPath = 'edit';
  if (copy) modalRootPath = 'copy';

  const { show, size, handleCloseModal } = useModal(modalRootPath);

  // Query string manipulation
  const [searchParams] = useSearchParams();
  const customerId = searchParams.get('customerId');

  // SET ADITIONAL DATA MANAGED BY OTHER COMPONENTS
  const [aditionalData, setAditionalData] = useState({
    items: {
      product_amount: [],
      product_cat: [],
      product_desc: [],
      product_name: [],
      product_id: [],
      product_qty: [],
      product_rate: [],
      product_tax: [],
      product_cat_name: [],
    },
  });
  // SELECTED PRODUCTS TABLE -
  // new items array with all items
  const handleAddItems = (newItems) => {
    const nextItem = {
      product_amount: [],
      product_cat: [],
      product_cat_name: [],
      product_name: [],
      product_desc: [],
      product_id: [],
      product_qty: [],
      product_rate: [],
      product_tax: [],
    };
    newItems.map((item) => {
      nextItem.product_amount.push(item.item_price_before_tax);
      nextItem.product_cat.push(item.category_id);
      nextItem.product_cat_name.push(item.category_name);
      nextItem.product_desc.push(item.description);
      nextItem.product_id.push(item.product_id);
      nextItem.product_name.push(item.product_name);
      nextItem.product_rate.push(item.price_before_tax);
      nextItem.product_qty.push(item.quantity);
      nextItem.product_tax.push(item.tax_rate);
      return null;
    });
    const nextItems = { items: nextItem };
    setAditionalData((prevItems) => ({ ...prevItems, ...nextItems }));
  };

  // Formik form
  const customerInformation = [
    {
      formGroup: 'Customer information',
      groupChildren: [
        {
          name: 'client_id',
          label: 'Select customer',
          formType: 'customer',
          initialValue: customerId || customer.id || '',
          type: Yup.string().required('Please select a customer'),
          required: true,
          className: 'input-wide',
          labelAction: (
            <Link
              to={`new-customer?customerType=billing${customerId ? `&customerId=${customerId}` : ''}`}
            >
              New Customer
            </Link>
          ),
        },
        {
          name: 'customer_email',
          label: 'Customer email',
          formType: 'email',
          initialValue: customer.email,
          type: Yup.string().email('Not a proper email'),
          disabled: true,
          plaintext: true,
          readOnly: true,
          className: 'd-none',
        },
        {
          name: 'billing_address',
          label: 'Billing address',
          initialValue: customer.fullAddress,
          formType: 'address',
          type: Yup.string().required(
            'Please select a customer to display the address'
          ),
          disabled: true,
          plaintext: true,
          readOnly: true,
          className: 'd-none',
        },
      ],
    },
  ];
  const estimateInformation = [
    {
      formGroup: 'Estimate information',
      groupChildren: [
        {
          formType: 'date',
          initialValue: dates.dateCreated,
          label: 'Date created',
          name: 'quote_date_created',
          required: true,
          type: Yup.date().required('Please add the start date'),
        },
        {
          name: 'quote_due_date',
          label: 'Date end',
          initialValue: dates.dateDue,
          type: Yup.date()
            .min(
              Yup.ref('quote_date_created'),
              "End date can't be before start date"
            )
            .required('Please add the end date'),
          formType: 'date',
          required: true,
        },
        {
          name: 'quote_number',
          label: 'Estimate number',
          initialValue: estimate.number,
          type: Yup.string().required('Please add number'),
          required: true,
          disabled: true,
          plaintext: true,
          readOnly: true,
        },
      ],
    },
  ];
  const aditionalInformation = [
    {
      formGroup: 'Aditional data',
      groupChildren: [
        {
          name: 'quote_alternative_message',
          label: 'Statement notes',
          initialValue: notes.statementNotes,
          type: Yup.string(),
          formType: 'textarea',
          maxLength: 800,
        },
        {
          name: 'quote_project_description',
          label: 'Project description',
          initialValue: notes.description || '',
          type: Yup.string(),
          formType: 'textarea',
          maxLength: 1000,
        },
      ],
    },
  ];

  const formValues = [
    ...estimateInformation,
    ...customerInformation,
    ...aditionalInformation,
  ];
  const [aditionalDataFeedback, setAditionalDataFeedback] = useState(false);
  // Submit action
  const submit = useSubmit();
  const submitAction = (values) => {
    if (aditionalData.items.product_id.length === 0) {
      setAditionalDataFeedback(true);
      formik.setSubmitting(false);
      return;
    }
    setAditionalDataFeedback(false);
    const preNextValues = { ...values, ...aditionalData };
    if (id) preNextValues.id = id;
    const nextValues = { json: JSON.stringify(preNextValues) };
    submit(nextValues, { method: 'post' });
    formik.setSubmitting(false);
  };

  const [formik] = useFormikTemplate({
    initial: formValues,
    yupValues: formValues,
    submitAction,
  });

  return (
    <>
      <LBOffCanvas show={show} size={size} handleCloseModal={handleCloseModal}>
        <Outlet />
      </LBOffCanvas>
      <ActionErrorInformation />
      <FormWrapper
        id="CreateEstimate"
        handleSubmit={formik.handleSubmit}
        isSubmitting={formik.isSubmitting}
        dirty={formik.dirty}
        isValid={formik.isValid}
      >
        <CustomerInfoForm formik={formik} structure={customerInformation} />
        <CreateForm structure={estimateInformation} {...formik} />
        <h5 className="mb-4 mt-5">Items</h5>
        <SelectedProducts
          onAddItems={handleAddItems}
          initialItems={items}
          feedback={aditionalDataFeedback}
        />
        <CreateForm structure={aditionalInformation} {...formik} />
      </FormWrapper>
      <BlockerModal exceptions={[`${currentPath}/new-customer`, currentPath]} />
    </>
  );
}
