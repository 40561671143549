import React from 'react';
import { Col, Row } from 'react-bootstrap';
import StepToggler from './StepToggler';

function Left({ children }: { children: React.ReactNode }) {
  return <Col lg>{children}</Col>;
}

function Right({ children }: { children: React.ReactNode }) {
  return (
    <Col className="px-4" lg>
      {children}
    </Col>
  );
}

function Actions({ children }: { children: React.ReactNode }) {
  return (
    <div
      style={{
        position: 'sticky',
        bottom: '0',
        // marginBottom: '-1.2rem',
        marginTop: '-2.5rem',
        paddingTop: '2.5rem',
        background:
          'linear-gradient(hsla(0, 100%, 100%, 0) 0, hsla(0, 100%, 100%, 1) 30px)',
        height: 100,
      }}
      className="d-flex justify-content-between align-items-start gap-3 pb-1"
    >
      {children}
    </div>
  );
}

function Step({
  children,
  actions,
}: {
  children: React.ReactNode;
  actions: React.ReactNode;
}) {
  return (
    <>
      <StepToggler />
      <Row className="gx-5 gy-3 mt-1 mb-auto" style={{ alignSelf: 'stretch' }}>
        {children}
      </Row>
      {actions}
    </>
  );
}

Step.Left = Left;
Step.Right = Right;
Step.Actions = Actions;

export default Step;
