import { handleResposeAbstraction } from '../../API/fetchUtils';
import manageCredentialsAPI from '../../API/routesAPI/manageAccountAPI';

export const requestPasswordChangeAction = async () => {
  const response = await manageCredentialsAPI.requestPasswordChange();

  let redirectTo = '';

  if (response.status === 200) redirectTo = '..';

  return handleResposeAbstraction({
    response,
    redirectTo,
    showSuccess: true,
  });
};

export const passwordChangeAction = async ({ request }) => {
  const body = await request.formData();
  const response = await manageCredentialsAPI.updatePassword(body);

  let redirectTo = '';

  if (response.status === 200) redirectTo = '..';

  return handleResposeAbstraction({ response, redirectTo, showSuccess: true });
};

export const requestEmailChangeAction = async () => {
  const response = await manageCredentialsAPI.requestEmailChange();

  let redirectTo = '';

  if (response.status === 200) redirectTo = '..';

  return handleResposeAbstraction({
    response,
    redirectTo,
    showSuccess: true,
  });
};

export const emailChangeAction = async ({ request }) => {
  const body = await request.formData();
  const response = await manageCredentialsAPI.updateEmail(body);
  const token = body.get('token');

  let redirect = '';

  if (response.status === 200) redirect = `../verify-otp?token=${token}`;

  return handleResposeAbstraction({
    response,
    redirectTo: redirect,
    showSuccess: true,
  });
};

export const verifyOTPAction = async ({ request }) => {
  const body = await request.formData();
  const response = await manageCredentialsAPI.verifyEmailOTP(body);

  let redirect = '';

  if (response.status === 200) redirect = '..';

  return handleResposeAbstraction({
    response,
    redirectTo: redirect,
    showSuccess: true,
  });
};
