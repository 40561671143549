import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import { I_Data } from './propTypes';
import { LocationPin, Phone, Mail } from './Icons';

function FooterContent({
  s,
  foregroundColor,
  data,
}: {
  data: I_Data;
  foregroundColor: string;
  s: any;
}) {
  return (
    <>
      {data.user.contact?.address.city && data.user.contact?.address.state && (
        <View style={s.col}>
          <>
            <LocationPin color={foregroundColor} />
            <Text>
              {data.user.contact.address.city},{' '}
              {data.user.contact.address.state}
            </Text>
          </>
        </View>
      )}
      {data.user.contact?.phone ? (
        <View style={s.col}>
          <>
            <Phone color={foregroundColor} />
            <Text>{formatPhoneNumberIntl(data.user.contact.phone)}</Text>
          </>
        </View>
      ) : null}
      {data.user.contact?.email && (
        <View style={s.col}>
          <>
            <Mail color={foregroundColor} />
            <Text>{data.user.contact.email}</Text>
          </>
        </View>
      )}
      <View style={s.col}>
        <Text
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} / ${totalPages}`
          }
        />
      </View>
    </>
  );
}

export default FooterContent;
