/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import WidgetContainer from './WidgetContainer/WidgetContainer';
import TableWidget from './TableWidget/TableWidget';
import IconButton from '../../UI/IconButton/IconButton.tsx';
import { Edit, TrashCan } from '../../UI/Icons/Icons';
import LBTooltip from '../../UI/LBTooltip/LBTooltip.tsx';
import useWidgetFilter from './utils/useWidgetFilter';
import TablePlaceholder from './TableWidget/TablePlaceholder';
import StatusBadge from '../../UI/StatusBadge';
import { AppointmentToggle } from '../../Appointments/WeekView/WeekView';

function Appointments() {
  const {
    serverMessage,
    fetcherData,
    fetcherState,
    filter,
    handleSelectChange,
    fetcher,
  } = useWidgetFilter('appointments', 'nextSevenDays');
  const navigate = useNavigate();

  const goToAppointment = (id) => navigate(`/appointments/${id}`);

  const handleClick = (id) => {
    goToAppointment(id);
  };

  return (
    <WidgetContainer>
      <WidgetContainer.Header
        title="Appointments"
        url="/appointments"
        cta="Go to appointments"
      >
        <WidgetContainer.Select
          value={filter}
          onChangeHandler={handleSelectChange}
        >
          <option value="today">Today</option>
          <option value="nextSevenDays">Next 7 Days</option>
          <option value="nextMonth">Next Month</option>
          <option value="thisYear">This Year</option>
        </WidgetContainer.Select>
      </WidgetContainer.Header>
      <WidgetContainer.Body
        loadingComponent={
          <TableWidget>
            <TablePlaceholder />
          </TableWidget>
        }
        fetcherState={fetcherState}
        entries={fetcherData?.length}
        serverMessage={serverMessage}
      >
        <TableWidget>
          {fetcherData?.length > 0 &&
            fetcherData.map((appointment) => (
              <TableWidget.Item
                key={`${new Date(
                  appointment.end_date
                ).getTime()}-${appointment.name
                  .replace(/[^a-zA-Z]/g, '')
                  .toLocaleLowerCase()}`}
                status={appointment.status}
                onClick={() => handleClick(appointment.id)}
              >
                <dl className="left appointment__data-list">
                  <div>
                    <dd>
                      <StatusBadge
                        statusCode={appointment?.status}
                        type="appointment"
                        customClasses="table-widget__status-badge"
                      />
                    </dd>
                  </div>
                  <div>
                    <dt>Name: </dt>
                    <dd>{appointment.name}</dd>
                  </div>
                  {appointment.performer_name && (
                    <div>
                      <dt>Performer: </dt>
                      <dd>{appointment.performer_name}</dd>
                    </div>
                  )}
                  <div>
                    <dt>Due Date: </dt>
                    <dd>
                      {new Date(appointment.end_date).toLocaleString('en-US', {
                        hour: '2-digit',
                        minute: 'numeric',
                        day: '2-digit',
                        month: '2-digit',
                      })}
                    </dd>
                  </div>
                </dl>
                <div className="right appointment__control-group">
                  <LBTooltip tooltipText="Edit appointment">
                    <IconButton
                      as={Link}
                      to={`/appointments/${appointment.id}/edit`}
                      color="blue"
                      onClick={(e) => e.stopPropagation()}
                      onKeyDown={(e) => e.stopPropagation()}
                    >
                      <Edit />
                    </IconButton>
                  </LBTooltip>
                  <AppointmentToggle
                    fetcher={fetcher}
                    id={appointment.id}
                    status={appointment.status}
                  />
                  <LBTooltip tooltipText="Delete">
                    <fetcher.Form
                      method="post"
                      action={`/appointments/${appointment.id}/delete-util`}
                    >
                      <IconButton
                        type="submit"
                        color="red"
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={(e) => e.stopPropagation()}
                      >
                        <TrashCan />
                      </IconButton>
                    </fetcher.Form>
                  </LBTooltip>
                </div>
              </TableWidget.Item>
            ))}
        </TableWidget>
      </WidgetContainer.Body>
    </WidgetContainer>
  );
}

export default Appointments;
