import React from 'react';
import { Outlet } from 'react-router-dom';

function Onboarding() {
  return (
    <div
      className="d-flex w-100 h-100 mt-3 p-0 p-md-4 rounded"
      style={{ backgroundColor: 'white' }}
    >
      <Outlet />
    </div>
  );
}

export default Onboarding;
