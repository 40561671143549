import './App.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import { createPortal } from 'react-dom';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import RootPage from './pages/RootPage';
import ErrorPage from './pages/ErrorPage';
import ProtectedPage from './components/Login/ProtectedPage';
import PageNotFound from './pages/PageNotFound';
import { getMessagesRoutes } from './routes/messages/messagesRoutes';
import {
  auth,
  appointments,
  dashboard,
  customers,
  estimates,
  expenses,
  invoices,
  payments,
  products,
  settings,
  vendors,
  support,
} from './routes';
import userLoader from './routes/root/rootLA';
import reportsRoutes from './routes/reports/reportsRoutes';
import contactFormRoutes from './routes/contactForm/contactFormRoutes';
import checksRoutes from './routes/checks/checksRoutes';

const Router = createHashRouter([
  {
    path: '/',
    element: <ProtectedPage />,
    shouldRevalidate: ({ currentUrl }) => {
      // only revalidate if the submission originates from
      // the following routes
      return (
        currentUrl.pathname === '/settings' ||
        currentUrl.pathname === '/settings/templates' ||
        currentUrl.pathname === '/settings/trades'
      );
    },
    children: [
      {
        path: '',
        element: <RootPage />,
        errorElement: <ErrorPage />,
        loader: userLoader,
        handle: {
          crumb: () => 'Home',
        },
        id: 'root',
        children: [
          ...checksRoutes,
          ...customers,
          ...estimates,
          ...invoices,
          ...dashboard,
          ...expenses,
          ...payments,
          ...products,
          ...settings,
          ...support,
          ...vendors,
          ...appointments,
          ...getMessagesRoutes,
          ...reportsRoutes,
          {
            path: '*',
            element: <PageNotFound />,
          },
        ],
      },
    ],
  },
  ...auth,
  ...contactFormRoutes,
]);

function App() {
  return (
    <>
      <RouterProvider router={Router} />
      {document.getElementById('toastCointainers') &&
        createPortal(
          <ToastContainer position="bottom-right" transition={Slide} />,
          document.getElementById('toastCointainers')
        )}
    </>
  );
}
export default App;
