import React from 'react';

function ContactFormSuccess() {
  return (
    <div className="text-center">
      <h1 className="mb-3">Success!</h1>
      <p>Customer created successfully, thanks for your time!</p>
      <a href="https://leadsboxinvoice.com/" className="btn btn-primary mt-3">
        Know more about Leadsbox Invoice
      </a>
    </div>
  );
}

export default ContactFormSuccess;
