import MandatoryFieldsForm from './MandatoryFieldsForm';

export default function MandatoryFields({
  template,
}: {
  template: string;
}): JSX.Element {
  return (
    <>
      <p className="h4 fw-bolder me-5 d-block" />
      <strong className="me-5">{`${template} complete:`} </strong>
      <p />
      <MandatoryFieldsForm />
    </>
  );
}
