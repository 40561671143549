import React from 'react';
import Proptypes from 'prop-types';

function TextTitle({ children }) {
  return <div className="mt-5 mb-4 lb-text__box">{children}</div>;
}
TextTitle.propTypes = {
  children: Proptypes.node.isRequired,
};

export default TextTitle;
