import fetchAPI from '../fetchUtils';

const loginAPI = {
  login: async (body) => fetchAPI.postAnonymous('/user-login', body),
  register: async (body) =>
    fetchAPI.postAnonymous('/fake-account-create', body),
  resetPasswordRequest: async (body) =>
    fetchAPI.postAnonymous('/user-forgot-password', body),
  resetPassword: async (body) =>
    fetchAPI.postAnonymous(`/user-reset-password`, body),
  requestValidationEmail: async (body) =>
    fetchAPI.postWithAuth('/resend-verification-link', body),
};

export default loginAPI;
