import React from 'react';
import { Link, useOutletContext, useRouteLoaderData } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Step from '../Step';
import IconButton from '../../UI/IconButton/IconButton';
import { CaratBack } from '../../UI/Icons/Icons';
import CustomerForm from './CustomerForm';
import { I_QuickStartContext } from '../QuickStart';

function CustomerStep() {
  const {
    user: {
      onboarding_helpers: { customers_count: customersCount },
    },
  } = useRouteLoaderData('root') as any;
  const { customer, stepChangeHanlder } =
    useOutletContext() as I_QuickStartContext;

  function initUseExistingCustomer() {
    if (customer?.id) return true;
    if (customersCount) return true;
    return false;
  }

  const [useExistingCustomer, setUseExistingCustomer] = React.useState<boolean>(
    () => initUseExistingCustomer()
  );

  function handleToggleChange() {
    return setUseExistingCustomer((prev) => !prev);
  }

  // Set the step
  React.useEffect(() => stepChangeHanlder(1), []);

  const actions = (
    <Step.Actions>
      <IconButton as={Link} to=".." text="Back" outline={false}>
        <CaratBack />
      </IconButton>
      <div id="ob-customer-submit" />
    </Step.Actions>
  );

  return (
    <Step actions={actions}>
      <Step.Left>
        <h4 className="mb-4" style={{ fontWeight: 500 }}>
          First, create {customersCount ? 'or select' : ''} a customer
        </h4>
        <p>
          First you need {customersCount > 0 ? 'either select or' : 'to'} create
          a <strong>new customer</strong>, here’s an abridged form with only the
          required fields. You can also add notes, a fax/landline and a company
          to each customer from the{' '}
          <Link to="/customers/new">customers page</Link>.
        </p>
        {customersCount > 0 && (
          <Form.Check
            type="switch"
            label="Use an existing customer"
            id="create-or-select"
            checked={useExistingCustomer}
            onChange={() => handleToggleChange()}
            className="mt-4"
          />
        )}
      </Step.Left>
      <Step.Right>
        <CustomerForm useExistingCustomer={useExistingCustomer} />
      </Step.Right>
    </Step>
  );
}

export default CustomerStep;
