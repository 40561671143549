import {
  resetPasswordRequest,
  resetPasswordRequestSuccess,
  forgotPasswordNew,
  register,
  registerSuccess,
  resetPasswordSuccess,
  verifyEmail,
} from '../paths';
import ResetPasswordSuccess from '../../components/Login/ResetPasswordSuccess';
import ResetPasswordRequest from '../../components/Login/ResetPasswordRequest';
import ResetPassword from '../../components/Login/ResetPassword';
import LoginPage from '../../pages/LoginPage';
import Login from '../../components/Login/UserLogin';
import Register from '../../components/Login/Register';
import RegisterSuccess from '../../components/Login/RegisterSuccess';
import ResetPasswordRequestSuccess from '../../components/Login/ResetPasswordRequestSuccess';
import AuthErrorPage from '../../pages/AuthErrorPage';
import VerifyEmail from '../../components/Login/VerifyEmail';
import {
  authAction,
  registerAction,
  resetPasswordRequestAction,
  resetPasswordAction,
  requestValidationEmail,
} from './authLA';
import ResendEmail from '../../components/Login/ResendEmail';

const authRoutes = [
  {
    path: 'auth',
    element: <LoginPage />,
    errorElement: <AuthErrorPage />,
    children: [
      {
        path: `${resetPasswordRequest}`,
        element: <ResetPasswordRequest />,
        action: resetPasswordRequestAction,
      },
      {
        path: `${resetPasswordRequestSuccess}`,
        element: <ResetPasswordRequestSuccess />,
      },
      {
        path: `${forgotPasswordNew}`,
        element: <ResetPassword />,
        action: resetPasswordAction,
      },
      {
        path: `${resetPasswordSuccess}`,
        element: <ResetPasswordSuccess />,
      },
      {
        path: `${verifyEmail}`,
        element: <VerifyEmail />,
      },
      {
        path: 'resend-verification-email',
        action: requestValidationEmail,
        element: <ResendEmail />,
      },
      {
        path: `${register}`,
        element: <Register />,
        action: registerAction,
        shouldRevalidate: () => false,
      },
      {
        path: `${registerSuccess}`,
        element: <RegisterSuccess />,
      },
      {
        index: true,
        element: <Login />,
        action: authAction,
        shouldRevalidate: () => false,
      },
    ],
  },
];

export default authRoutes;
