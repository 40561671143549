import * as Yup from 'yup';
import { I_field } from './utils';
import { I_UserSettings, I_Fields } from './propTypes';

export const companyName: I_field = {
  name: 'user_company',
  label: 'Company Name',
  initialValue: '',
  required: true,
  type: Yup.string().required('Please complete the field'),
};

export const businessStructure = {
  name: 'business_structure',
  label: 'Business structure',
  required: true,
  initialValue: '',
  type: Yup.string().required('Required'),
  formType: 'select',
  options: [
    { value: 1, label: 'Limited Liability Company (LLC)' },
    { value: 2, label: 'Sole Proprietor' },
    { value: 3, label: 'Partnership' },
    { value: 4, label: 'S Corporation' },
    { value: 5, label: 'C Corporation' },
    { value: 6, label: 'Doing Business As (DBA)' },
  ],
};

export const getFromData = (data: I_UserSettings): I_Fields[] => {
  const fields = [companyName];
  if (!data?.business_structure) {
    fields.push(businessStructure);
  }
  return fields;
};
