import fetchAPI from '../fetchUtils';

const manageAccountAPI = {
  requestPasswordChange: async () =>
    fetchAPI.getWithAuth('/change-password-request'),
  updatePassword: async (body) =>
    fetchAPI.postWithAuth('/change-password', body),
  requestEmailChange: async () => fetchAPI.getWithAuth('/change-email-request'),
  updateEmail: async (body) =>
    fetchAPI.postWithAuth('/send-new-email-otp', body),
  verifyEmailOTP: async (body) =>
    fetchAPI.postWithAuth('/verify-new-email-otp', body),
};

export default manageAccountAPI;
