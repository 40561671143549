/* eslint-disable no-use-before-define */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link, Outlet, useLoaderData, useSubmit } from 'react-router-dom';
import { Alert, Button } from 'react-bootstrap';
import { number, string } from 'yup';
import useIsAuthorized from '../../../routes/protectedPages/useIsAuthorized';
import useFormikTemplate from '../../Forms/hooks/useFormikTemplate';
import FormWrapper from '../../Forms/builders/FormWrapper';
import CreateForm from '../../Forms/CreateForm';
import LBOffCanvas from '../../UI/LBOffCanvas';
import useModal from '../../UI/LBOffCanvas/useModal';
import VehiclesTable from './VehiclesTable';

import './MileagesPage.scss';
import Card from '../../UI/Card';

export const testEndGreaterThanStart = (startField) => ({
  name: 'endGreaterThanStart',
  exclusive: false,
  params: {},
  message: 'End value must be greater than start value',
  test(value) {
    if (!value) return true;
    return Number(value) > Number(this.parent[startField]);
  },
});

function AssetsPage() {
  const isAuthorized = useIsAuthorized({
    tiersAllowed: ['tier_3'],
  });
  const { vehicles } = useLoaderData() || {};
  const { show, size, handleCloseModal } = useModal('mileages', [
    'new-vehicle',
    'update-vehicle',
  ]);
  const [vehicleId, setVehicleId] = React.useState(null);
  const [periodState, setPeriodState] = React.useState('currentYear');

  const setMileages = async ({ period, vehicle }) => {
    let year;
    let currentVehicleId;

    if (period) {
      setPeriodState(period);
      if (!vehicleId) return;
      currentVehicleId = vehicleId;
      year =
        period === 'currentYear'
          ? new Date().getFullYear()
          : new Date().getFullYear() - 1;
    }

    if (vehicle) {
      setVehicleId(vehicle);
      currentVehicleId = vehicle;
      year =
        periodState === 'currentYear'
          ? new Date().getFullYear()
          : new Date().getFullYear() - 1;
    }

    const selectedVehicle = vehicles.find(
      (item) => item.id === currentVehicleId
    );
    const { start, end } =
      selectedVehicle?.mileages?.find((item) => item.year === year) || {};

    const lastYearEndMileage =
      year === new Date().getFullYear() &&
      selectedVehicle?.mileages?.find((item) => item.year === year - 1)?.end;
    await setValue('start', start || lastYearEndMileage || '0');
    await setValue('end', end || '0');
  };

  const fields = [
    {
      name: 'period',
      label: 'Period',
      initialValue: 'currentYear',
      formType: 'toggleGroup',
      className: 'flex__span-12',
      options: [
        { label: 'Current Year', value: 'currentYear', color: 'primary' },
        { label: 'Last Year', value: 'lastYear', color: 'primary' },
      ],
      change: (e) => setMileages({ period: e.target.value }),
    },
    {
      name: 'vehicle_id',
      label: 'Select Vehicle',
      initialValue: '',
      className: 'flex__span-5',
      formType: 'vehicleSelect',
      type: string().required(),
      options: vehicles,
      change: (e) => {
        setMileages({ vehicle: e });
      },
    },
    {
      name: 'start',
      label: 'Start Miles',
      initialValue: '',
      formType: 'inputGroup',
      className: 'flex__span-2',
      inputType: 'numeric',
      type: number().typeError('Enter a number please'),
      config: {
        rightLabel: 'mi',
      },
    },
    {
      name: 'end',
      label: 'End Miles',
      initialValue: '',
      formType: 'inputGroup',
      className: 'flex__span-2',
      inputType: 'numeric',
      type: number()
        .typeError('Enter a number please')
        .test(testEndGreaterThanStart('start')),
      config: {
        rightLabel: 'mi',
      },
    },
  ];

  const submit = useSubmit();
  const submitAction = (values) => {
    const nextValues = {
      id: values.vehicle_id,
      start: Number(values.start),
      end: Number(values.end),
      year:
        values.period === 'currentYear'
          ? new Date().getFullYear()
          : new Date().getFullYear() - 1,
    };

    submit(nextValues, {
      method: 'POST',
      encType: 'application/json',
    });
  };

  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
    enableReinitialize: false,
  });

  async function setValue(field, value) {
    await formik.setFieldValue(field, value);
  }

  return isAuthorized ? (
    <div>
      <FormWrapper
        id="assets-form"
        handleSubmit={formik.handleSubmit}
        isSubmitting={formik.isSubmitting}
        dirty={formik.dirty}
        isValid={formik.isValid}
        className="assets__form-wrapper flex__form-wrapper"
        portalId="miles-submit"
      >
        <div className="form-two">
          <div className="flex__span-12 mb-4 mt-3 d-flex justify-content-between">
            <h4 className="mb-0">Set Mileage</h4>
            {vehicles?.length > 0 && (
              <Button size="sm" as={Link} to="./vehicles">
                Manage Vehicles
              </Button>
            )}
          </div>
          {vehicles?.length > 0 ? (
            <>
              <CreateForm structure={fields} {...formik} />
              <div
                id="miles-submit"
                className="flex__span-3"
                style={{ paddingTop: '1.25rem' }}
              />
            </>
          ) : (
            <Alert
              variant="info"
              style={{
                gridColumn: '1 / -1',
                minHeight: 140,
                display: 'grid',
                placeContent: 'center',
              }}
            >
              <span>
                <Link as={Link} to="./vehicles/new-vehicle">
                  Add a vehicle
                </Link>{' '}
                to start tracking mileages
              </span>
            </Alert>
          )}
        </div>
      </FormWrapper>

      {vehicles?.length > 0 && <VehiclesTable vehicles={vehicles} />}

      <LBOffCanvas show={show} size={size} handleCloseModal={handleCloseModal}>
        <Outlet />
      </LBOffCanvas>
    </div>
  ) : (
    <Card>
      <p className="mb-4 text-center fw-bold mt-4">
        You need to have an active subscription to access this feature.
      </p>
      <Button as={Link} to="/settings/subscription">
        Manage
      </Button>
    </Card>
  );
}

export default AssetsPage;
