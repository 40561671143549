/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */

import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { CHART_COLORS } from '../testData';

import './BarCharts.scss';
import formatCurrency from '../utils/formatCurrency';

// This is for proper formatting of the months, if we end
// up using quarters we must modify this enum
const MONTHS = {
  Jan: 'January',
  Feb: 'February',
  Mar: 'March',
  Apr: 'April',
  May: 'May',
  Jun: 'June',
  Jul: 'July',
  Aug: 'August',
  Sep: 'September',
  Oct: 'October',
  Nov: 'November',
  Dec: 'December',
};

function tickFormatter(tick) {
  if (tick < 1000) {
    return Math.floor(tick); // No abbreviation needed, keep it as is.
  }
  if (tick < 1000000) {
    const kValue = (tick / 1000).toFixed(2);
    const formattedKValue = kValue.replace(/\.?0*$/, ''); // Remove trailing ".0"
    return `${formattedKValue}K`;
  }

  const mValue = (tick / 1000000).toFixed(2);
  const formattedMValue = mValue.replace(/\.?0*$/, ''); // Remove trailing ".0"
  return `${formattedMValue}M`;
}

const renderLegend = (props) => {
  const { payload } = props;
  return (
    <ul className="bar-legend">
      {payload.map((entry) => (
        <li
          key={`item-${entry.dataKey}`}
          className="item"
          style={{ '--legend-color': entry.color }}
        >
          <span className="color-square" />
          <span className="field-name">{entry.payload.label}</span>
        </li>
      ))}
    </ul>
  );
};

// "label" comes from rechart's API, in our case it represents the month
// label, we grab the correct label from the "label" enum we pass into the
// bar chart (in the main component)
function CustomTooltip({ active, payload, label, labels }) {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${MONTHS[label]}`}</p>
        <ul className="value-container">
          {payload.map((item, index) => (
            <li
              key={`${item.value}-${item.name}`}
              className="data-pair"
              style={{ '--legend-color': CHART_COLORS[index] }}
            >
              <span className="key">{labels[item.name]}</span>:{' '}
              <span className="value">{formatCurrency(item.value)}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return null;
}

function ArrowBtn({ direction = 'right', ...delegated }) {
  const customRotation = {
    right: 0,
    down: 90,
    left: 180,
    up: 270,
  };

  return (
    <button
      type="button"
      className="btn btn-secondary bar-chart__arrow-btn"
      {...delegated}
    >
      <svg
        style={{ transform: `rotateZ(${customRotation[direction]}deg)` }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
      >
        <polyline points="13.24 45.13 34.76 24 13.24 2.87" />
      </svg>
    </button>
  );
}

function SimpleBarChart({ chartData, labels }) {
  // Create state to determine current page (default = "first")
  const [currentPage, setCurrentPage] = React.useState(() => {
    const currentMonth = new Date().getMonth();
    if (currentMonth > 5) return 1;
    return 0;
  }); // 1 | 0

  // if (!chartData) {
  //   return <SimpleBarChartPlaceholder />;
  // }

  // Split chartData in 2 sub-arrays (we should always get a 12 length array)
  const firstPage = chartData.filter((item, index) => index < 6 && item);
  const secondPage = chartData.filter((item, index) => index >= 6 && item);
  const pages = [firstPage, secondPage];

  return (
    <div className="bar-chart__container">
      <ResponsiveContainer height="100%">
        <BarChart
          width={800}
          data={pages[currentPage]}
          barCategoryGap="15%"
          margin={{
            top: 10,
            right: 5,
            left: -16,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} strokeWidth={0.25} />
          <XAxis style={{ fontSize: '0.65rem' }} dataKey="name" />
          <YAxis
            tickFormatter={(tick) => tickFormatter(tick)}
            type="number"
            style={{ fontSize: '0.65rem' }}
          />
          <Tooltip
            isAnimationActive={false}
            content={<CustomTooltip labels={labels} />}
          />
          <Legend verticalAlign="bottom" content={renderLegend} />
          {/* 
          We create a Bar group for each key in the chartData object
          (we pick the first entry) and specify the correct color and
          formatted label
        */}
          {Object.keys(pages[currentPage][0])
            .filter((key) => key !== 'name')
            .map((key, index) => (
              <Bar
                key={key}
                label={labels[key]}
                dataKey={key}
                fill={CHART_COLORS[index]}
              />
            ))}
        </BarChart>
      </ResponsiveContainer>
      <div className="bar-chart__arrow-container">
        <ArrowBtn
          direction="left"
          disabled={currentPage === 0}
          onClick={() => setCurrentPage(0)}
        />
        <ArrowBtn
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(1)}
        />
      </div>
    </div>
  );
}

export default SimpleBarChart;
