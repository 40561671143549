/* eslint-disable react/no-array-index-key */

import { parseISO } from 'date-fns';
import { Card } from 'react-bootstrap';
import { I_Message } from './SendTo';

export default function MessageHistory({
  messages,
}: {
  messages: I_Message[];
}) {
  return (
    <div className="d-flex flex-column gap-3">
      {messages.map((message, index) => (
        <Card key={index} className="p-0" style={{ fontSize: '0.8rem' }}>
          <Card.Header>
            <Card.Title className="mb-0 pt-1" style={{ fontSize: '0.9rem' }}>
              {parseISO(message.date_time).toLocaleString('en-US', {
                dateStyle: 'long',
                timeStyle: 'short',
              })}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Card.Text className="text-muted">Sent to:</Card.Text>
            <Card.Text>{message.email}</Card.Text>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
}
