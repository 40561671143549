import { useSearchParams, useSubmit } from 'react-router-dom';
import * as Yup from 'yup';
import GenericForm from '../Forms/builders/GenericForm';
import LBOffCanvas from '../UI/LBOffCanvas';

function ChangePasswordForm() {
  const [searchParams] = useSearchParams();

  const userId = searchParams.get('uid');
  const token = searchParams.get('token');

  const fields = [
    {
      name: 'old_password',
      label: 'Old Password',
      initialValue: '',
      type: Yup.string()
        .strict(true)
        .required('Your old password is required!'),
      formType: 'password',
      required: true,
      autocomplete: 'current-password',
    },
    {
      name: 'new_password',
      label: 'New Password',
      initialValue: '',
      type: Yup.string().strict(true).required('A new password is required!'),
      formType: 'password',
      required: true,
      autocomplete: 'new-password',
    },
    {
      name: 'new_password_confirm',
      label: 'Confirm New Password',
      initialValue: '',
      type: Yup.string()
        .strict(true)
        .required('Please confirm your new password!')
        .oneOf([Yup.ref('new_password'), null], 'Passwords must match!'),
      formType: 'password',
      required: true,
      autocomplete: 'false',
    },
  ];
  const submit = useSubmit();
  const submitAction = (values) =>
    submit({ ...values, uid: Number(userId), token }, { method: 'post' });

  return (
    <LBOffCanvas.Left>
      <LBOffCanvas.Header>Change password</LBOffCanvas.Header>

      <GenericForm
        fields={fields}
        submitAction={submitAction}
        formId="changePasswordForm"
        submitLabel="Change password"
      />
    </LBOffCanvas.Left>
  );
}

export default ChangePasswordForm;
