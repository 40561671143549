/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { HideEye, ShowEye } from '../../UI/Icons/Icons';
import IconWrapper from '../../Customers/CustomersCsv/IconWrapper';

function LBFormControl({
  element,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
}) {
  const [overrideFormType, setOverrideFormType] = React.useState(false);

  const inputType = overrideFormType ? 'text' : element?.formType;

  const formControl = () => (
    <Form.Control
      as={element.formType === 'textarea' ? 'textarea' : 'input'}
      autoComplete={element?.autocomplete || 'on'}
      disabled={element?.disabled}
      inputMode={
        element?.inputMode === 'numeric' ? 'decimal' : element?.inputMode
      }
      isInvalid={touched[element.name] && !!errors[element.name]}
      isValid={touched[element.name] && !errors[element.name]}
      maxLength={element?.maxLength}
      name={element.name}
      onBlur={handleBlur}
      onChange={(e) => {
        if (e.nativeEvent.data === ',')
          e.target.value = e.target.value.replace(/,/g, '.');
        // if (element?.inputMode === 'numeric') e.target.value = +e.target.value;
        handleChange(e);
        if (element?.change) element.change(e);
      }}
      placeholder={element.placeholder}
      plaintext={element?.plaintext}
      required={element?.required}
      rows={4}
      type={inputType}
      value={values[element.name]}
      onFocus={(e) => {
        if (e.target.value === '0') e.target.select();
      }}
    />
  );

  if (element.formType === 'password') {
    return (
      <InputGroup
        className={`${touched[element.name] && !errors[element.name] ? 'is-valid' : ''} ${touched[element.name] && !!errors[element.name] ? 'is-invalid' : ''}`}
      >
        {formControl()}
        <Button
          variant="secondary"
          onClick={() => setOverrideFormType((prev) => !prev)}
        >
          <IconWrapper stroke="currentColor">
            {overrideFormType ? <HideEye /> : <ShowEye />}
          </IconWrapper>
        </Button>
      </InputGroup>
    );
  }

  return formControl();
}

export default LBFormControl;
