import propTypes from 'prop-types';

const CHECK_DATA_SHAPE = propTypes.shape({
  category_id: propTypes.string,
  paymeny_date: propTypes.string,
  memo: propTypes.string,
  total: propTypes.number,
  printState: propTypes.number,
  items: propTypes.arrayOf(
    propTypes.shape({
      description: propTypes.string,
      amount: propTypes.number,
      invoice: propTypes.number,
    })
  ),
  recipient: propTypes.oneOfType([
    propTypes.shape({
      id: propTypes.number,
      name: propTypes.string,
      lastname: propTypes.string,
      address: propTypes.shape({
        zipcode: propTypes.string,
        street_address_1: propTypes.string,
        street_address_2: propTypes.string,
        city: propTypes.string,
        state: propTypes.string,
        country: propTypes.string,
        full_address: propTypes.string,
      }),
    }),
    propTypes.string,
  ]),
});

export default CHECK_DATA_SHAPE;
