import { handleResposeAbstraction } from '../../API/fetchUtils';
import supportAPI from '../../API/routesAPI/supportAPI';

const supportMailAction = async ({ request }) => {
  const body = await request.formData();
  const response = await supportAPI.create(body);
  const redirectTo = '/';
  return handleResposeAbstraction({
    response,
    redirectTo,
    showSuccess: true,
  });
};

export default supportMailAction;
