import customersAPI from '../../API/routesAPI/customersAPI';
import { handleResposeAbstraction } from '../../API/fetchUtils';
import customerResponse from './validateGetCustomers';

// Replace after Prasun fixes the reponse!
const nextCustomers = (api) => {
  const res = api.customers.map((customer) => ({
    ...customer,
    balance: Number(customer.balance.slice(1).replace(/,/g, '')),
  }));

  return {
    ...api,
    customers: res,
  };
};

export const getCustomersLoader = async () => {
  const response = await customersAPI.getAll();
  let result = await response.json();
  if (result?.customers) {
    result = nextCustomers(result);
  }
  return handleResposeAbstraction({ response, result });
};

export const deleteCustomerAction = async ({ params }) => {
  const { customerId } = params;
  const response = await customersAPI.delete(customerId);
  return handleResposeAbstraction({
    response,
    redirectTo: '/customers',
    showSuccess: true,
  });
};

export const createCustomerAction = async ({ request, hardRedirect }) => {
  const body = await request.formData();
  const response = await customersAPI.create(body);
  const result = await response.json();

  let redirectTo = false;

  // Check for hard redirects
  if (result?.customer?.client_id && hardRedirect) {
    const customerType = body.get('customerType');
    const qsServiceClientId = JSON.parse(body.get('serviceClientId'));
    const qsClientId = JSON.parse(body.get('customerId'));

    // Redirect when it's a regular (billing) customer
    if (customerType === 'billing')
      redirectTo = `${hardRedirect}?customerId=${result?.customer?.client_id}${qsServiceClientId ? `&serviceClientId=${qsServiceClientId}` : ''}`;

    // Redirect when it's a service info customer
    if (customerType === 'service')
      redirectTo = `${hardRedirect}?serviceClientId=${result?.customer?.client_id}${qsClientId ? `&customerId=${qsClientId}` : ''}`;
  }

  // Vanilla redirect (no modal, from customers/new page)
  if (result?.customer?.client_id && !hardRedirect) {
    redirectTo = `/customers/${result?.customer?.client_id}`;
  }

  return handleResposeAbstraction({
    response,
    result,
    redirectTo,
  });
};

export const updateCustomerAction = async ({ request, params }) => {
  const body = await request.formData();
  const { customerId } = params;

  const formDataCustomerId = body.get('id');
  const modalRedirect = body.get('modal_redirect');
  if (!formDataCustomerId) body.append('id', customerId);

  const response = await customersAPI.update(body);
  const result = await response.json();
  const redirectTo = result?.customer?.client_id
    ? `/customers/${result?.customer?.client_id}`
    : false;
  return handleResposeAbstraction({
    response,
    result,
    redirectTo: formDataCustomerId ? modalRedirect : redirectTo,
  });
};

export const getCustomerLoader = async ({ params }) => {
  const { customerId } = params;
  const body = new FormData();
  body.append('customer_id', customerId);
  const response = await customersAPI.get(body);
  let result = await response.json();
  if (result.message === 'success') {
    result = customerResponse(result);
  }
  return handleResposeAbstraction({ response, result });
};

export const updateCustomerNotesAction = async ({ request, params }) => {
  const body = await request.formData();
  const { customerId } = params;
  if (customerId) {
    body.append('customer_id', customerId);
  }
  const response = await customersAPI.updateNotes(body);
  return handleResposeAbstraction({
    response,
    showSuccess: true,
    redirectTo: `/customers/${customerId}`,
  });
};

export const getCustomerActivityLoaderAction = async ({ params, request }) => {
  const searchUrl = new URL(request.url);
  let urlTime = searchUrl.searchParams.get('time');
  if (urlTime === null) {
    urlTime = 'last_year';
  }
  const formData = new FormData();
  formData.append('customer_id', params.customerId);
  formData.append('time', urlTime);

  const response = await customersAPI.getCustomerActivity(formData);
  return handleResposeAbstraction({ response });
};

export const importCsvAction = async ({ request }) => {
  const body = await request.formData();
  const response = await customersAPI.importCsv(body);
  const result = await response.json();
  return handleResposeAbstraction({
    response,
    result,
  });
};

export const exportCsvAction = async ({ request }) => {
  const body = await request.formData();
  const response = await customersAPI.exportCsv(body);
  const result = await response.json();
  return handleResposeAbstraction({
    response,
    redirectTo: result?.url || '',
    result,
  });
};
