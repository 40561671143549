import React from 'react';
import { Button } from 'react-bootstrap';
import {
  Link,
  useNavigation,
  useSearchParams,
  useSubmit,
} from 'react-router-dom';
import { string } from 'yup';
import TextTitle from '../UI/text/TextTitle';
import GenericForm from '../Forms/builders/GenericForm.tsx';
import ActionErrorInformation from '../../API/ActionErrorInformation';

function VerifyEmail() {
  const navigation = useNavigation();
  const [URLSearchParams] = useSearchParams();
  const status = URLSearchParams.get('status');
  const successfulValidation = status === 'success';

  const fields = [
    {
      name: 'email',
      label: 'Email',
      initialValue: '',
      placeholder: 'name@email.com',
      type: string().email().required('Please add your email'),
      formType: 'email',
      required: true,
    },
    {
      name: 'should_redirect',
      label: 'Hidden Field',
      initialValue: 'true',
      type: string(),
      formType: 'email',
      required: true,
      disabled: true,
      className: 'd-none',
    },
  ];

  const submit = useSubmit();
  const submitAction = (values) => {
    submit(values, {
      action: '/auth/resend-verification-email',
      method: 'post',
    });
  };

  if (successfulValidation)
    return (
      <>
        <TextTitle>
          <h1>Welcome to LeadsBoxInvoice</h1>
          <p className="lb-text__subtitle mt-0">E-mail validation</p>
        </TextTitle>
        <div>
          <p>Your e-mail address has been successfully validated.</p>
        </div>
        <div className="mt-5">
          <Button as={Link} to="/">
            Go to Dashboard
          </Button>
        </div>
      </>
    );

  return (
    <>
      <TextTitle>
        <h1 className="text-danger">Error</h1>
        <p className="lb-text__subtitle mt-0">
          We couldn&apos;t process your validation
        </p>
      </TextTitle>

      <ActionErrorInformation />

      <div>
        {status === 'invalid-token' ? (
          <p>
            The provided token was invalid. The token may have expired, you can
            try sending another email with a new code. Please try again with a
            new token.
          </p>
        ) : (
          <p>
            There was an unknown error while attempting to validate your email
            address. Please try again with a new token.
          </p>
        )}
        <div className="mt-5">
          <GenericForm
            fields={fields}
            submitAction={submitAction}
            formId="loginForm"
            navigation={navigation.state !== 'idle'}
            submitLabel="Resend email"
          />
        </div>
      </div>
    </>
  );
}

export default VerifyEmail;
