import React from 'react';
import {
  Link,
  useNavigate,
  useOutletContext,
  useRouteLoaderData,
  useSearchParams,
} from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Step from '../Step';
import IconButton from '../../UI/IconButton/IconButton';
import { CaratBack } from '../../UI/Icons/Icons';
import { I_QuickStartContext } from '../QuickStart';
import IconWrapper from '../../Customers/CustomersCsv/IconWrapper';
import { EstimateIcon, InvoiceIcon } from './icons';
import CustomerInfo from '../CustomerStep/CustomerInfo';

function FinishStep() {
  const {
    user: {
      onboarding_helpers: { prod_and_serv_count: prodAndServCount },
    },
  } = useRouteLoaderData('root') as any;
  const { customer, stepChangeHanlder, prodOrServ, prodChangeHandler } =
    useOutletContext() as I_QuickStartContext;
  const navigate = useNavigate();
  const [URLSearchParams] = useSearchParams();
  const productId = URLSearchParams.get('productId');

  // This prevents the user from staying here if the page is reloaded
  React.useEffect(() => {
    if (!customer?.id) {
      navigate('/onboarding/customer');
    }
    if (!prodAndServCount) {
      navigate('/onboarding/prod-and-serv');
    }
  }, [customer.id]);

  React.useEffect(() => {
    if (productId) prodChangeHandler(productId);
  }, [productId]);

  // Set the step
  React.useEffect(() => stepChangeHanlder(3), []);

  const actions = (
    <Step.Actions>
      <IconButton as={Link} to="../prod-and-serv" text="Back" outline={false}>
        <CaratBack />
      </IconButton>
    </Step.Actions>
  );

  return (
    <Step actions={actions}>
      <Step.Left>
        <h2 className="mb-4" style={{ fontWeight: 500, fontSize: '1.7rem' }}>
          You&apos;re all set!
        </h2>
        <p className="mb-5">
          Now that you have at least one customer and a product/service, you can
          go ahead and either create an estimate (you can later on convert this
          to an invoice, should your customer accept it) or directly create an
          invoice.
        </p>

        {customer?.id && <CustomerInfo customer={customer} abridged />}
      </Step.Left>
      <Step.Right>
        <div className="col-12">
          <Button
            variant="secondary"
            className="ps__custom-button"
            onClick={() =>
              navigate('/estimates/new', {
                state: { customer, product: prodOrServ },
              })
            }
          >
            <IconWrapper size={64} viewBox={128}>
              <EstimateIcon />
            </IconWrapper>
            <span className="d-flex flex-column">
              <span className="ps__custom-button__title">Estimate</span>
              <p className="ps__custom-button__body">
                Create an estimate for your customer, you can then send it and
                they will be able to check the proposal. Then you can later on
                convert this to an invoice or modify it!
              </p>
            </span>
          </Button>
        </div>
        <div className="col-12">
          <Button
            variant="secondary"
            className="ps__custom-button"
            onClick={() =>
              navigate('/invoices/new', {
                state: { customer, product: prodOrServ },
              })
            }
          >
            <IconWrapper size={64} viewBox={128}>
              <InvoiceIcon />
            </IconWrapper>
            <span className="d-flex flex-column">
              <span className="ps__custom-button__title">Invoice</span>
              <p className="ps__custom-button__body">
                You can also directly create an invoice, which can then later on
                receive partial or complete payments.
              </p>
            </span>
          </Button>
        </div>
      </Step.Right>
    </Step>
  );
}

export default FinishStep;
