import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './SelectedProductsTable.scss';
import SelectedProductsTable from './SelectedProductsTable';
import AddProduct from './AddProduct';
import ModalForActions from '../../UI/ModalForActions';

function SelectedProducts({ onAddItems, initialItems, feedback }) {
  const [items, setItems] = useState(initialItems || []);
  const [itemToChange, setItemToChange] = useState(null);
  const [show, setShow] = useState(false);

  const setCurrentItems = (nextItems) => {
    onAddItems(nextItems);
    setItems(nextItems);
  };

  useEffect(() => {
    if (initialItems) setCurrentItems(initialItems);
  }, []);

  const addItemHandler = (item) => {
    const index = typeof item?.index === 'number' ? item?.index : items.length;
    const nextItems = [...items];
    nextItems.splice(+index, 1, item);
    setCurrentItems(nextItems);
    setItemToChange(null);
  };

  useEffect(() => {
    if (itemToChange !== null) {
      setShow(true);
    }
  }, [itemToChange]);

  useEffect(() => {
    if (initialItems) {
      setCurrentItems(initialItems);
    }
  }, []);
  const close = () => {
    setItemToChange(null);
    setShow(false);
  };
  return (
    <>
      <div className="horizontal-overflow">
        <div className="wrapper mb-4">
          <SelectedProductsTable
            feedback={feedback}
            items={items}
            setItemToChange={setItemToChange}
            setCurrentItems={setCurrentItems}
            setShow={setShow}
          />
        </div>
      </div>

      <ModalForActions
        show={show}
        onHide={close}
        title={
          itemToChange ? `Update ${itemToChange.product_name}` : 'New item'
        }
      >
        <AddProduct
          item={itemToChange}
          onAddItem={addItemHandler}
          onClose={() => setShow(false)}
        />
      </ModalForActions>
    </>
  );
}

SelectedProducts.propTypes = {
  onAddItems: PropTypes.func.isRequired,
  initialItems: PropTypes.arrayOf(PropTypes.shape({})),
  feedback: PropTypes.bool,
};

SelectedProducts.defaultProps = {
  initialItems: null,
  feedback: false,
};
export default SelectedProducts;
