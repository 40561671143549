import React from 'react';
import { useLoaderData } from 'react-router-dom';
import { toDate, parseISO } from 'date-fns';
import LBOffCanvas from '../UI/LBOffCanvas';
import TextField from '../UI/text/TextField';
import ChecksActions from './ChecksActions';
import PrintStateManager from './PrintStateManager';
import Item from './Item';
import FieldData from '../UI/text/FieldData';
import StatusBadge from '../UI/StatusBadge';
import formatCurrency from '../Dashboard/Widgets/utils/formatCurrency';
import ModalTitle from '../UI/text/ModalTitle';
import FieldLabel from '../UI/text/FieldLabel';

function Check() {
  const { data } = useLoaderData(); // This is a mock data will be response including recipients

  const date = new Date(toDate(parseISO(data.payment_date))).toLocaleDateString(
    'en-US',
    {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }
  );
  return (
    <LBOffCanvas.Wrapper>
      <LBOffCanvas.Left>
        <LBOffCanvas.Header>
          <ModalTitle title="Check" subtitle={`C-000000${data.id}`} />
          <div className="d-flex" style={{ fontSize: 'initial' }}>
            <FieldData variant="lb-modal__price">
              {formatCurrency(+data.total) || 'missing'}
            </FieldData>
            <StatusBadge statusCode={data.print_state} type="check" />
          </div>
        </LBOffCanvas.Header>

        <TextField label="">{data.memo}</TextField>
        <div className="d-flex justify-content-between gap-2 mt-2">
          <TextField label="Payment date">{date}</TextField>
          <div>
            <FieldLabel>Actions</FieldLabel>
            <div className="d-flex">
              <PrintStateManager id={data.id} state={data.print_state} />
              <ChecksActions id={data.id} state={data.print_state} />
            </div>
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between pb-3">
          <h3>{`${data.recipient.name} ${data.recipient?.lastname ? data.recipient.lastname : ''}`}</h3>
        </div>
        <TextField label="Address">
          {data.recipient.address.full_address || 'missing'},
        </TextField>
        <hr />
        <h3>Items</h3>
        {data.items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Item item={item} status={+data.print_state} key={index} />
        ))}
      </LBOffCanvas.Left>
    </LBOffCanvas.Wrapper>
  );
}

export default Check;
