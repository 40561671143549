import { BASE_URL as baseUrl } from '../API/constants';

export const BASE_URL = baseUrl;
export const register = '/auth/register';
export const registerSuccess = '/auth/register-success';
export const login = '/auth';
export const resetPasswordRequest = '/auth/reset-password-request';
export const resetPasswordRequestSuccess =
  '/auth/reset-password-request-success';
export const forgotPasswordNew = '/auth/reset-password-new';
export const resetPasswordSuccess = '/auth/reset-password-success';
export const verifyEmail = '/auth/verify-email';
