import { Link, useLoaderData } from 'react-router-dom';
import { checkIfDev } from '../../../routes/root/rootLA';
import IconButton from '../../UI/IconButton/IconButton';
import { Help, Gear } from '../../UI/Icons/Icons';
import LBTooltip from '../../UI/LBTooltip/LBTooltip';
import NotificationMenu from '../NotificationMenu/NotificationMenu';

function NavActions({ handleCloseMenu }: { handleCloseMenu?: () => void }) {
  const result = useLoaderData() as any;
  if (!result) return null;
  const isDev = result?.user && checkIfDev(result?.user?.user_email);

  const handleClick = () => {
    return handleCloseMenu ? handleCloseMenu() : null;
  };

  return (
    <>
      <LBTooltip tooltipText="Help">
        <IconButton
          size={22}
          style={{ borderRadius: 1000, minWidth: 40, height: 40 }}
          as={Link}
          to="/support"
          onClick={handleClick}
        >
          <Help />
        </IconButton>
      </LBTooltip>
      {isDev && (
        <LBTooltip tooltipText="Override Sub (UI only)">
          <IconButton
            size={22}
            style={{ borderRadius: 1000, minWidths: 40, height: 40 }}
            as={Link}
            to="/settings/dev-subscription-manage"
            onClick={handleClick}
          >
            <Gear />
          </IconButton>
        </LBTooltip>
      )}

      <NotificationMenu />
    </>
  );
}

export default NavActions;
