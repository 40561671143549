const getHeaderTooltip = (
  { customers_count: customersCount, prod_and_serv_count: prodAndServCount },
  type
) => {
  let message = '';
  let disableButton = false;
  if (customersCount === 0 && prodAndServCount === 0) {
    message = `To create an ${type}, you need to have at least one product/service and one customer.`;
    disableButton = true;
  }

  if (customersCount === 0 && prodAndServCount > 0) {
    message = `To create an ${type}, you need at least one customer, but you can create it from the new invoice page.`;
  }

  if (customersCount > 0 && prodAndServCount === 0) {
    message = `To create an ${type}, you need at least one product/service.`;
    disableButton = true;
  }

  return { message, disableButton };
};

export default getHeaderTooltip;
