import React from 'react';
import propTypes from 'prop-types';
import LBTooltip from '../LBTooltip/LBTooltip.tsx';
import IconButton from '../IconButton/IconButton.tsx';
import {
  Add,
  Edit,
  TrashCan,
  Print,
  Checkmark,
  AddTray,
  RemoveTray,
  CheckTray,
} from '../Icons/Icons';

function ActionButton({ action, handler, fetcherState }) {
  let button;
  switch (action) {
    case 'voided':
      button = {
        label: 'Voided',
        handler,
        color: 'red',
        icon: <TrashCan />,
      };
      break;
    case 'edit':
      button = {
        label: 'Edit',
        handler,
        color: 'blue',
        icon: <Edit />,
      };
      break;
    case 'delete':
      button = {
        label: 'Delete',
        handler,
        color: 'red',
        icon: <TrashCan />,
      };
      break;
    case 'deleteDisabled':
      button = {
        label: 'Delete Disabled',
        handler,
        style: { cursor: 'disabled' },
        color: 'grey',
        icon: <TrashCan />,
      };
      break;
    case 'add':
      button = {
        label: 'Add',
        handler,
        color: 'green',
        icon: <Add />,
      };
      break;
    case 'print':
      button = {
        label: 'Print',
        handler,
        color: 'green',
        icon: <Print />,
      };
      break;
    case 'addToTray':
      button = {
        label: 'Add to Tray',
        handler,
        color: 'green',
        icon: <AddTray />,
      };
      break;
    case 'removeFromTray':
      button = {
        label: 'Remove from tray',
        handler,
        color: 'red',
        icon: <RemoveTray />,
      };
      break;
    case 'goToTray':
      button = {
        label: 'Go to tray',
        handler,
        color: 'yellow',
        icon: <CheckTray />,
      };
      break;
    case 'printed':
      button = {
        label: 'Printed this cant be modified',
        handler,
        color: 'green',
        icon: <Checkmark />,
      };
      break;
    default:
      button = null;
  }

  return (
    <span style={{ width: '38px' }}>
      <LBTooltip tooltipText={button?.label}>
        <IconButton
          disabled={fetcherState && fetcherState !== 'idle'}
          color={button?.color}
          style={button?.style}
          data-testid={button?.label}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            button?.handler();
          }}
        >
          {button?.icon}
        </IconButton>
      </LBTooltip>
    </span>
  );
}

ActionButton.propTypes = {
  fetcherState: propTypes.oneOf(['idle', 'loading', 'submitting']),
  action: propTypes.oneOf([
    'edit',
    'delete',
    'add',
    'print',
    'goToTray',
    'printed',
    'addToTray',
    'removeFromTray',
    'voided',
  ]).isRequired,
  handler: propTypes.func,
};

export default ActionButton;
