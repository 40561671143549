import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  useSubmit,
  useFetcher,
  useParams,
  useOutletContext,
  useRouteLoaderData,
  useSearchParams,
} from 'react-router-dom';
import { Alert } from 'react-bootstrap';
/* eslint-disable  no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import ModalTitle from '../UI/text/ModalTitle';
import FormWrapper from '../Forms/builders/FormWrapper';
import extractValues from '../Helpers/extractValues';
import CreateForm from '../Forms/CreateForm/CreateForm';
import PDFViewer from '../Checks/PDF/lbPDFViewer';

export default function SendEmail() {
  const messageFetcher = useFetcher();
  const { email, subject } = useOutletContext();
  const { estimateId, invoiceId, paymentId } = useParams();
  const [searchParams] = useSearchParams();
  const pdfURL = searchParams.get('pdf');
  const module = estimateId ? 'estimate' : invoiceId ? 'invoice' : 'receipt';
  const {
    user: { stripe_configuration: isStripeEnabled },
  } = useRouteLoaderData('root') || {};

  useEffect(() => {
    if (messageFetcher.state === 'idle' && !messageFetcher.data) {
      if (estimateId) messageFetcher.load(`/settings/messages/estimates`);
      if (invoiceId) messageFetcher.load(`/settings/messages/invoices`);
      if (paymentId) messageFetcher.load(`/settings/messages/receipts`);
    }
  }, [messageFetcher]);
  const routeFetecher = useRouteLoaderData('root');

  const errors = '';
  const fields = [
    {
      name: 'from_email',
      label: 'From',
      placeholder: 'Mail from',
      initialValue: routeFetecher?.user?.user_email || '',
      type: Yup.string().required('Please add'),
      formType: 'email',
      required: true,
    },
    {
      name: 'to_email',
      label: 'To',
      placeholder: 'Mail to',
      initialValue: email,
      type: Yup.string().required('Please add'),
      formType: 'email',
      required: true,
    },
    {
      name: 'subject',
      label: 'Subject',
      placeholder: 'Subject',
      initialValue: subject,
      type: Yup.string().required('Please add'),
      required: true,
    },
    {
      name: 'send_stripe_link',
      label: 'Send Stripe Link',
      formType: 'checkbox',
      initialValue: !!isStripeEnabled,
      type: Yup.string(),
      className: isStripeEnabled ? '' : 'd-none',
    },
    {
      name: 'message',
      label: 'Content',
      placeholder: '',
      initialValue: messageFetcher?.data?.data?.email_content || '',
      type: Yup.string().required('Please add').required('Please add'),
      formType: 'textarea',
      required: true,
      className: 'text-area',
      maxLength: 1500,
    },
  ];

  const submit = useSubmit();
  const initialValues = extractValues('initialValue', [...fields]);
  const validationSchema = Yup.object().shape(
    extractValues('type', [...fields])
  );
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async () => {
      const nextValues = {
        ...formik.values,
        module_type: module,
        id: estimateId || invoiceId || paymentId,
        msg_type: 'email',
      };
      submit(nextValues, { method: 'post' });
      formik.setSubmitting(false);
    },
  });
  return (
    <div className="send-message d-flex flex-column  modal-overflow mb-5">
      <section className="border-0">
        <ModalTitle title="" subtitle="Send e-mail" />
        <p className="lb-modal__data mb-4 mt-2 ">
          You can send messages to your clients with this document as attachment
        </p>

        {errors ? (
          <Alert variant="danger" className="mt-2 small">
            {errors}
          </Alert>
        ) : (
          ''
        )}

        <FormWrapper
          id="CreateVendor"
          handleSubmit={formik.handleSubmit}
          isSubmitting={formik.isSubmitting}
          dirty={formik.dirty}
          isValid={formik.isValid}
          label="Send"
          portalId="modalBottom"
        >
          <CreateForm structure={fields} {...formik} />
        </FormWrapper>
        <div className="mt-3">
          <p className="small mb-2">Attachment</p>
          <div
            className="d-flex flex-column "
            style={{
              width: '100%',
              height: 'auto',
              aspectRatio: '8.5 / 11',
            }}
          >
            <PDFViewer
              title="Invoice preview"
              instance={{ url: pdfURL, loading: false }}
              style={{
                marginTop: 18,
                flex: '1 0 100%',
              }}
              showToolbar={false}
            />
          </div>
        </div>
        <div id="pdf-save" />
      </section>
    </div>
  );
}
