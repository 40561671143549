import React from 'react';
import { Link, useFetcher } from 'react-router-dom';
import PropTypes from 'prop-types';
import { parseISO } from 'date-fns';
import * as Popover from '@radix-ui/react-popover';
import { Badge } from 'react-bootstrap';
import IconButton from '../../UI/IconButton/IconButton.tsx';
import LBTooltip from '../../UI/LBTooltip/LBTooltip.tsx';
import { Cross, Bell, TrashCan } from '../../UI/Icons/Icons';

import './NotificationMenu.scss';

function Item({ children, variant, date, source, id, seen, link }) {
  const deleteFetcher = useFetcher();
  const Tag = link ? Link : 'div';

  return (
    <Tag
      to={link}
      className={`notification-popover__item ${variant}`}
      style={{ cursor: link ? 'pointer' : 'auto' }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-baseline gap-2">
          {!seen && (
            <span
              style={{
                height: '10px',
                width: '10px',
                backgroundColor: 'var(--bs-red)',
                borderRadius: '1000px',
              }}
            />
          )}

          <Badge
            style={{ fontSize: '0.65rem', height: 'min-content' }}
            bg={variant}
            text={variant === 'warning' ? 'dark' : undefined}
          >
            {source.toUpperCase()}
          </Badge>

          <span className="notification-popover__item-date">
            {parseISO(date).toLocaleDateString('en-US', { dateStyle: 'short' })}
          </span>
        </div>

        <deleteFetcher.Form
          method="POST"
          action={`/dashboard/notifications/delete?id=${id}`}
          style={{ display: 'flex' }}
        >
          <LBTooltip tooltipText="Delete">
            <IconButton
              size={14}
              color={variant}
              type="submit"
              style={{
                heigth: '10px',
                padding: '6px',
              }}
              outline={false}
              onClick={(e) => e.stopPropagation()}
            >
              <TrashCan />
            </IconButton>
          </LBTooltip>
        </deleteFetcher.Form>
      </div>
      <p>{children}</p>
    </Tag>
  );
}

Item.propTypes = {
  children: PropTypes.node,
  id: PropTypes.number,
  variant: PropTypes.oneOf(['success', 'info', 'danger', 'warning']),
  date: PropTypes.string,
  source: PropTypes.string,
  seen: PropTypes.bool,
  link: PropTypes.string,
};

function NotificationMenu() {
  const notificationFetcher = useFetcher();
  const [open, setOpen] = React.useState();

  React.useEffect(() => {
    if (notificationFetcher.state === 'idle' && !notificationFetcher.data) {
      notificationFetcher.load('/dashboard/notifications');
    }

    const delay = 1000 * 60 * 5; // 5 minutes
    const refreshTimer = setInterval(() => {
      return notificationFetcher.load('/dashboard/notifications');
    }, delay);

    return () => clearInterval(refreshTimer);
  }, [notificationFetcher]);

  const preReverseNotifications = notificationFetcher?.data?.data;
  const notifications =
    preReverseNotifications && [...preReverseNotifications].reverse();
  const fetcherState = notificationFetcher.state;
  const unseenNotifications =
    notifications?.length &&
    notifications.filter((n) => n.seen === 0).map((n) => n.id);

  const markAsRead = () => {
    if (!unseenNotifications || unseenNotifications?.length === 0) return;

    notificationFetcher.submit(
      { ids: unseenNotifications },
      {
        method: 'post',
        action: '/dashboard/notifications',
        encType: 'application/json',
      }
    );

    // This is quite janky but if we don't add the timeout the server
    // doesn't have enough time to update the DB
    setTimeout(
      () => notificationFetcher.load('/dashboard/notifications'),
      1000
    );
  };

  const handleOpenChange = (e) => {
    if (e === false) {
      markAsRead();
    }
    setOpen(e);
  };

  return (
    <Popover.Root open={open} onOpenChange={(e) => handleOpenChange(e)}>
      <Popover.Trigger asChild>
        <div style={{ position: 'relative' }}>
          <LBTooltip tooltipText="Notifications">
            <IconButton
              size={22}
              style={{ borderRadius: '400px' }}
              disabled={fetcherState !== 'idle'}
            >
              <Bell />
            </IconButton>
          </LBTooltip>
          {fetcherState === 'idle' && unseenNotifications?.length > 0 && (
            <span className="noticiation-popover__dot">
              {unseenNotifications.length > 0
                ? unseenNotifications.length
                : undefined}
            </span>
          )}
        </div>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className="notification-popover__container shadow"
          sideOffset={10}
        >
          <Popover.Arrow className="notification-popover__arrow" />

          <header className="notification-popover__header">
            <div>
              <h4>Notifications</h4>
              {notifications?.length > 0 && (
                <notificationFetcher.Form
                  action="/dashboard/notifications/delete?all=true"
                  method="POST"
                >
                  <button
                    type="submit"
                    className="notification-popover__delete-all"
                  >
                    Delete all notifications
                  </button>
                </notificationFetcher.Form>
              )}
            </div>
            <Popover.Close asChild>
              <IconButton size={10}>
                <Cross />
              </IconButton>
            </Popover.Close>
          </header>
          <div className="notification-popover__item-wrapper">
            {fetcherState === 'idle' &&
              (notifications === undefined || notifications?.length === 0) && (
                <p className="notification-popover__empty">
                  You have no notifications
                </p>
              )}
            {fetcherState === 'loading' && (
              <p className="notification-popover__empty">
                Loading notifications...
              </p>
            )}
            {fetcherState === 'idle' &&
              notifications?.length > 0 &&
              notifications
                .filter((n, i) => i < 9)
                .map(({ id, date, variant, source, title, seen, link }) => (
                  <Item
                    key={id}
                    id={id}
                    date={date}
                    variant={variant}
                    source={source}
                    seen={!!seen}
                    link={link}
                  >
                    {title}
                  </Item>
                ))}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

export default NotificationMenu;
