import { useRouteLoaderData } from 'react-router-dom';
import OptionalFields from './OptionalFields';
import { I_UserRoot, I_OptionalFields } from './helpers/propTypes';
import { isDataAvailable, getIncompleteFields } from './helpers/utils';
import MandatoryFields from './MandatoryFields';

function IsPdfAllowed({
  template,
  children,
}: {
  template: string;
  children: JSX.Element;
}): JSX.Element {
  // HOOKS
  const root = useRouteLoaderData('root') as any;

  // VARIABLES DEFINITIONS
  const { user } = root;
  // LOGIC
  const checker = isDataAvailable(user);
  const optionalData: I_OptionalFields[] = getIncompleteFields(checker);

  return (
    <div className="d-flex flex-column w-100 justify-content-center">
      {!checker.mandatory && <MandatoryFields template={template} />}
      {checker.mandatory && children}
      {optionalData.length > 0 && <OptionalFields fields={optionalData} />}
    </div>
  );
}
export default IsPdfAllowed;
