/* eslint-disable react/prop-types */
import React from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import Cropper from './Cropper';
import { useImageCropContext } from './ImageCropProvider';
import { dataURLtoFile, readFile } from './utils';
import { ZoomSlider } from './Sliders';
import {
  isValidDimensions,
  readUploadedFileAsImage,
} from '../FileDragAndDrop/File';

import './ImageCrop.scss';
import { BASE_URL } from '../../../API/constants';

// Constants
const ALLOWED_TYPES = ['image/png', 'image/jpeg'];

// Helpers
const checkType = (file, types) => types.includes(file.type);
const checkSize = (file, size) => file.size < size;

function ImageCrop({
  element,
  values,
  touched,
  errors,
  setFieldValue,
  setTouched,
}) {
  // File input stuff
  const [fileError, setFileError] = React.useState([]);
  const fileInputField = React.useRef({});
  const isFieldInvalid = touched[element.name] && !!errors[element.name];
  const isFieldValid = touched[element.name] && !errors[element.name];

  const [hoverState, setHoverState] = React.useState(null);

  // Modal stuff
  const [show, setShow] = React.useState(false);
  const handleClose = () => {
    setFieldValue(element.name, '');
    setHoverState(false);
    setShow(false);
  };

  // Cropper
  const { getProcessedImage, setImage, resetStates, resetTransformations } =
    useImageCropContext();
  const [preview, setPreview] = React.useState(() => {
    if (!values[element.name]?.includes('storage')) return null;

    return `${BASE_URL.replace('api', '')}/${values[element.name]}`;
  });

  const handleRemoveImage = () => {
    setPreview(null);
    element.config.deleteAction();
    setFieldValue(element.name, '');
    element.config.updateFiles(element.name, null);
  };

  const handleDone = async () => {
    const avatar = await getProcessedImage();

    const asFile = dataURLtoFile(avatar, `${element.name}.png`);
    element.config.updateFiles(element.name, asFile);
    setTouched({ ...touched, [element.name]: true });
    setPreview(avatar);
    setHoverState(false);
    setShow(false);
    resetStates();
  };

  const handleFileChange = async () => {
    setFileError([]);
    setPreview(null);
    element.config.deleteAction();

    if (!fileInputField.current.files[0])
      return setFieldValue(element.name, '');

    setFieldValue(element.name, fileInputField?.current?.value);
    const isValidType = checkType(
      fileInputField.current.files[0],
      ALLOWED_TYPES
    );
    const isValidSize = checkSize(
      fileInputField.current.files[0],
      1048576 * element.config.fileSize.maxSizeInMB
    ); // Size in bytes
    const nextErrors = [];

    if (!isValidType) nextErrors.push('Invalid file type');

    if (!isValidSize) nextErrors.push(element.config.fileSize.error);

    if (!isValidSize || !isValidType) {
      setFieldValue(element.name, '');
      setFileError(nextErrors);
      return null;
    }

    if (
      fileInputField.current.files[0].type === 'image/jpeg' ||
      fileInputField.current.files[0].type === 'image/png'
    ) {
      const file = fileInputField.current.files[0];
      const fileImage = await readUploadedFileAsImage(file, setFileError);
      if (!isValidDimensions(fileImage, element.config.dimensions)) {
        return setFileError([element.config.dimensions.error]);
      }

      setShow(true);
      const imageDataUrl = await readFile(file);
      setImage(imageDataUrl);
    }

    return null;
  };

  return (
    <>
      <div
        className={` ${isFieldInvalid ? 'is-invalid' : ''} ${isFieldValid ? 'is-valid' : ''}`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '220px',
          position: 'relative',
        }}
        onMouseEnter={() => setHoverState(true)}
        onMouseLeave={() => setHoverState(false)}
      >
        <Form.Control
          style={{
            height: '100%',
            width: '100%',
            opacity: 0,
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          ref={fileInputField}
          type="file"
          value=""
          required={element?.required}
          name={element.name}
          onChange={(e) => {
            handleFileChange(e);
          }}
          accept={ALLOWED_TYPES.join(', ')}
          disabled={element.disabled}
          isValid={isFieldValid}
          isInvalid={isFieldInvalid}
        />
        <div
          className={`${hoverState ? 'image-crop__is-hovering' : ''} cropper__validation ${isFieldInvalid ? 'is-invalid' : ''} ${isFieldValid ? 'is-valid' : ''}`}
          style={{
            borderBottomLeftRadius: fileError?.length > 0 ? 0 : 8,
            borderBottomRightRadius: fileError?.length > 0 ? 0 : 8,
          }}
        >
          {preview ? (
            <div className="d-flex w-100 h-100 justify-content-center gap-4">
              <div
                className="position-relative h-100 overflow-hidden"
                style={{
                  flexShrink: 0,
                }}
              >
                <img
                  src={preview}
                  className="object-cover"
                  style={{
                    height: '100%',
                    width: 'auto',
                  }}
                  alt="Selected preview"
                />
                {hoverState && (
                  <div className="image-crop__delete-overlay">
                    <div className="w-100 p-4 position-absolute top-50 start-50 translate-middle">
                      <p
                        style={{
                          fontSize: '0.75rem',
                          lineHeight: '140%',
                          marginBottom: '16px',
                        }}
                      >
                        Click or drag another image. This will remove your
                        current image.
                      </p>
                      <Button
                        style={{ pointerEvents: 'all', width: 'fit-content' }}
                        onClick={handleRemoveImage}
                        variant="danger"
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="w-100">
              <h6
                style={{
                  textDecoration: 'underline',
                  color: 'var(--bs-primary)',
                }}
              >
                Drag here or click
              </h6>
              <small className="mt-2">to add and crop an image</small>
            </div>
          )}
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Modal heading
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="crop-modal__container">
            <div className="crop-container">
              <Cropper />
            </div>
            <div className="d-flex w-100 flex-column">
              <ZoomSlider />
              <div className="d-flex align-self-end gap-3 mt-auto">
                <Button
                  size="sm"
                  variant="tertiary"
                  onClick={resetTransformations}
                >
                  Reset changes
                </Button>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={() => fileInputField.current.click()}
                >
                  Select another image
                </Button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              onClick={async () => {
                await handleDone();
              }}
            >
              Save changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {fileError?.length > 0 && (
        <Alert
          className="small w-100"
          style={{
            whiteSpace: 'pre',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
          variant="danger"
        >
          {fileError.map((error) =>
            // eslint-disable-next-line prefer-template
            fileError?.length > 1 ? error + '\n' : error
          )}
        </Alert>
      )}
    </>
  );
}

export default ImageCrop;
