import React from 'react';
import * as Yup from 'yup';
import { Outlet, Form, useRouteLoaderData, useSubmit } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import Card from '../UI/Card';
import location from '../../img/icon-location.png';
import envelope from '../../img/icon-envelop.png';
import iconPhone from '../../img/icon-phone.png';
import iconName from '../../img/sidebar/icon-customers-b.png';
import GenericForm from '../Forms/builders/GenericForm';
import LBMapContainer from '../Appointments/LBMapContainer';
import formatAddress from '../Appointments/utils/formatAddress';

function CustomerViewDetails() {
  const { data } = useRouteLoaderData('getCustomer');
  const { id, name, address, contact, notes } = data;

  const fields = [
    {
      name: 'ref_info', // this should change to notes
      label: 'Customer details notes',
      placeholder: '',
      initialValue: notes || '',
      type: Yup.string().required('Please complete the field'),
      formType: 'textarea',
    },
  ];
  const submit = useSubmit();
  const submitAction = (values) => {
    submit(values, {
      method: 'post',
      action: `/customers/${id}/updateNotes`,
    });
  };

  return (
    <div className="row">
      <div className="col-lg-8 ">
        <Card>
          <h2 className="lb-modal__title">Customer information</h2>
          <hr />
          <div className="row pt-3 pb-3">
            <div className="col-lg-6">
              <div className="lb-field lb-field--img">
                <img src={iconName} alt="" />
                <p className="lb-label">Name</p>
                <p className="lb-data">
                  {name?.fullname || 'No name register'}
                </p>
              </div>
              <div className="lb-field lb-field--img">
                <img src={location} alt="" />
                <p className="lb-label">Address</p>
                <p className="lb-data">
                  {address?.full_address ? (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                        formatAddress(address)
                      )}`}
                    >
                      {formatAddress(address, true)}
                    </a>
                  ) : (
                    'No address register'
                  )}
                </p>
              </div>
              <div className="lb-field lb-field--img">
                <img src={envelope} alt="" />
                <p className="lb-label">Email</p>
                <p className="lb-data">
                  {contact?.email || 'No email register'}
                </p>
              </div>
              <div className="lb-field lb-field--img">
                <img src={iconPhone} alt="" />
                <p className="lb-label">Phone</p>
                <p className="lb-data">
                  {contact?.phone ? (
                    <a href={`tel:${contact?.phone}`}>
                      {formatPhoneNumberIntl(contact?.phone)}
                    </a>
                  ) : (
                    'No phone register'
                  )}
                </p>
              </div>
              <div className="lb-field lb-field--img">
                <img src={iconPhone} alt="" />
                <p className="lb-label">Mobile</p>

                <p className="lb-data">
                  {' '}
                  {contact?.mobile ? (
                    <a href={`tel:${contact?.mobile}`}>
                      {formatPhoneNumberIntl(contact?.mobile)}
                    </a>
                  ) : (
                    'No phone register'
                  )}
                </p>
              </div>
            </div>
            <div className="col-lg-6 mt-3 mt-lg-0">
              <LBMapContainer
                address={address?.full_address}
                mapContainerStyle={{ height: '100%', minHeight: '300px' }}
              />
            </div>
          </div>
          <GenericForm fields={fields} submitAction={submitAction} />
        </Card>
      </div>
      <div className="col-lg-4 mt-4 mt-lg-0">
        <Outlet />
        <Form
          method="post"
          action="delete"
          onSubmit={(event) => {
            // eslint-disable-next-line
            if (!confirm('Please confirm you want to delete this record.')) {
              event.preventDefault();
            }
          }}
        >
          <Button variant="danger" className="mt-3 w-100" type="submit">
            Delete customer
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default CustomerViewDetails;
