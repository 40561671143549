import SupportPage from '../../components/Support/SupportPage';
import GenericPage from '../../pages/GenericPage';
import supportMailAction from './supportLA';

const supportRoutes = [
  {
    path: '',
    element: <GenericPage />,
    handle: {
      crumb: () => 'Support',
    },
    children: [
      { path: 'support', element: <SupportPage />, action: supportMailAction },
    ],
  },
];

export default supportRoutes;
