import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import { I_ListItem, I_OptionalFields } from './helpers/propTypes';

function ListItem({ label, link }: I_ListItem): JSX.Element {
  return (
    <Link to={link}>
      <small>{label}</small>
    </Link>
  );
}

// eslint-disable-next-line react/prop-types
export default function OptionalFields({
  fields,
}: {
  fields: I_OptionalFields[];
}) {
  const style = {
    display: 'flex',
    columnGap: '10px',
  };
  return (
    <div className="d-flex flex-column align-items-center h-100 justify-content-center w-100 mt-3 ">
      <div className="d-flex flex-column align-items-center w-100">
        <Alert
          variant="info"
          className="w-100 d-flex align-items-baseline flex-wrap mb-0 mx-2"
        >
          <div className="d-flex gap-2">
            <p className="pe-3 fw-bold">
              <small>Optional pdf data:</small>
            </p>
            <ul style={style} className="m-0 d-flex flex-wrap">
              {fields.map((field) => (
                <li key={field.key}>
                  <ListItem label={field.label} link={field.link} />
                </li>
              ))}
            </ul>
          </div>
        </Alert>
      </div>
    </div>
  );
}
