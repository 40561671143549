import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useFetcher, useRouteLoaderData } from 'react-router-dom';

function ResendEmailAlert() {
  const sendEmailFetcher = useFetcher({ key: 'resend-verification-email' });
  const {
    user: { user_email: userEmail },
  } = useRouteLoaderData('root') as any;
  const [showAlert, setShowAlert] = React.useState(true);

  const requestEmail = () => {
    sendEmailFetcher.submit(
      { email: userEmail },
      { method: 'POST', action: '/auth/resend-verification-email' }
    );
  };

  React.useEffect(() => {
    if (!sendEmailFetcher?.data || sendEmailFetcher?.state !== 'idle') return;

    if (sendEmailFetcher?.data?.message === 'success') setShowAlert(false);
  }, [sendEmailFetcher]);

  return (
    <Alert
      className="small gap-3 d-flex flex-column flex-md-row align-items-center justify-content-between"
      variant="warning"
      show={showAlert}
    >
      You need to verify your email address.
      <Button
        onClick={requestEmail}
        type="submit"
        variant="warning"
        size="sm"
        disabled={sendEmailFetcher.state !== 'idle'}
      >
        Send new verification link
      </Button>
    </Alert>
  );
}

export default ResendEmailAlert;
