import { redirect } from 'react-router-dom';
import { BASE_URL } from './constants';
import { toastHandler } from './toastHandler';

const getToken = () => localStorage.getItem('token');

export const checkTokenValidity = ({ currentUrl }) => {
  const token = getToken();
  if (!token) {
    // This only seems to be a problem in localhost, but to prevent it we
    // check if it's a URL where we can't really extract the local redirect
    // (no hash router)
    const validUrl = currentUrl.indexOf('#') !== -1;
    const body = {
      message: 'invalid_token',
      alert: 'Session expired, please login again',
      url: validUrl ? currentUrl?.substring(currentUrl.indexOf('#') + 2) : null,
    };
    const options = {
      ok: false,
      status: 498,
    };
    return new Response(JSON.stringify(body), options);
  }
  return token;
};

// Responses handlers
export const handleResponse = ({ status, result, redirectTo, mapFt }) => {
  switch (status) {
    case 200:
      if (redirectTo) return redirect(redirectTo);
      return mapFt(result);
    case 498:
      localStorage.setItem('token', null);
      return redirect(`/auth${result?.url ? `?redirect=${result.url}` : ''}`);
    default:
      return result;
  }
};
/* eslint-disable no-param-reassign */
export const handleResposeAbstraction = async ({
  response,
  result, // result is the response.json()
  redirectTo = false,
  showSuccess = false,
  mapFt = (a) => a,
}) => {
  if (!result) {
    result = await response.json();
  }
  toastHandler({ ok: response?.ok, result, showSuccess });
  return handleResponse({
    status: response?.status,
    result,
    redirectTo,
    mapFt,
  });
};

const request = async ({ url, body, contentType, auth, requestType }) => {
  const myHeaders = new Headers();
  const currentUrl = window?.location?.href || null; // This is the RR (FE) URL

  // if token is valid append and required append to the headers else retun 498
  if (auth) {
    const response = checkTokenValidity({ currentUrl });
    if (response?.status === 498) return response;
    myHeaders.append('auth_token', response);
  }
  // if content type is required append to the headers
  if (contentType) {
    myHeaders.append('Content-Type', contentType);
  }
  const urlAPI = `${BASE_URL}${url}`;
  const requestOptions = {
    ...(requestType === 'GET' && { method: 'GET' }),
    ...(requestType === 'POST' && { method: 'POST', body }),
    headers: myHeaders,
  };
  try {
    const response = await fetch(urlAPI, requestOptions);
    if (response.status === 429) {
      const responseBody = {
        message: 'Too many requests, please try again later',
        alert: 'Too many requests, please try again later',
      };
      const options = {
        ok: false,
        status: 429,
      };
      return new Response(JSON.stringify(responseBody), options);
    }
    if (response.status === 404) {
      const responseBody = {
        message: 'Page not found',
        alert: 'Page not found',
      };
      const options = {
        ok: false,
        status: 404,
      };
      return new Response(JSON.stringify(responseBody), options);
    }
    if (response.status === 405) {
      const responseBody = {
        message: 'Method not allowed',
        alert: 'Method not allowed',
      };
      const options = {
        ok: false,
        status: 405,
      };
      return new Response(JSON.stringify(responseBody), options);
    }
    if (response.status === 500) {
      const responseBody = {
        message: 'Database error, please try again later',
        alert: 'Database error, please try again later',
      };
      const options = {
        ok: false,
        status: 500,
      };
      return new Response(JSON.stringify(responseBody), options);
    }
    return response;
  } catch (error) {
    const responseBody = {
      message: 'Database error, please try again later',
      alert: 'Database error, please try again later',
    };
    const options = {
      ok: false,
      status: 500,
    };
    return new Response(JSON.stringify(responseBody), options);
  }
};

const fetchAPI = {
  getAnonymous: async (url) => request({ url, requestType: 'GET' }),
  getWithAuth: async (url) => request({ url, auth: true, requestType: 'GET' }),
  postWithAuth: async (url, body, contentType) =>
    request({ url, body, contentType, requestType: 'POST', auth: true }),
  postAnonymous: async (url, body, contentType) =>
    request({ url, body, contentType, requestType: 'POST' }),
};

export default fetchAPI;
