/* eslint-disable no-dupe-keys */
import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import Card from '../components/UI/Card';
import logo from '../img/logo/logo_invoice_manager.svg';

function LoginPage({ Children }) {
  return (
    <div
      className="d-flex w-100 d-flex justify-content-center bg-primary"
      style={{ minHeight: '100vh', minHeight: '100dvh' }}
    >
      <Card className="card--login mt-5 mb-5">
        <div className="d-flex  align-items-center">
          <img
            src={logo}
            alt=""
            style={{ marginTop: '1rem', maxWidth: '180px' }}
          />
        </div>
        <Outlet />
        {Children}
      </Card>
    </div>
  );
}
LoginPage.propTypes = {
  Children: PropTypes.node,
};

LoginPage.defaultProps = {
  Children: null,
};
export default LoginPage;
