import ChangeEmailForm from '../../components/ChangeEmail/ChangeEmailForm';
import ChangeEmailRequest from '../../components/ChangeEmail/ChangeEmailRequest';
import ChangePasswordForm from '../../components/ChangePassword/ChangePasswordForm';
import ChangePasswordRequest from '../../components/ChangePassword/ChangePasswordRequest';
import {
  emailChangeAction,
  passwordChangeAction,
  requestEmailChangeAction,
  requestPasswordChangeAction,
  verifyOTPAction,
} from './manageCredentials';
import VerifyEmailOTP from '../../components/ChangeEmail/VerifyEmailOTP';

const manageCredentialsRoutes = [
  {
    path: 'change-email-form',
    element: <ChangeEmailForm />,
    action: emailChangeAction,
  },
  {
    path: 'change-email-request',
    element: <ChangeEmailRequest />,
    action: requestEmailChangeAction,
  },
  {
    path: 'verify-otp',
    element: <VerifyEmailOTP />,
    action: verifyOTPAction,
  },
  {
    path: 'change-password-form',
    element: <ChangePasswordForm />,
    action: passwordChangeAction,
  },
  {
    path: 'change-password-request',
    element: <ChangePasswordRequest />,
    action: requestPasswordChangeAction,
  },
];

export default manageCredentialsRoutes;
