/* eslint-disable react/prop-types */
import React, { useId, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';

export default function DragFileInput({ handleFile, label, name, support }) {
  const formId = useId();
  const inputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      handleFile(file);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const setFormDataValues = (event) => {
    if (event.target.files.length === 0) return;
    handleFile(event.target.files[0]);
  };

  return (
    <label
      htmlFor={`${formId}__input`}
      className={
        dragActive
          ? 'drag-active small File__label File__wrapper'
          : 'small File__label File__wrapper'
      }
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      {/* 
        We were showing the label here, it looks weird (since we already show the label above)
        but since this may have a use I don't recall, I just added "d-none" to hide it for now
      */}
      <span className="File__label-text mt-3 d-none">{label}</span>
      <div className="d-flex justfy_items_center align-items-center">
        <Button
          type="button"
          className="upload-button"
          variant="link"
          onClick={onButtonClick}
        >
          Drag or browse file
        </Button>
      </div>

      <div className="small mt-3">
        <div className="small p-3" role="alert">
          Suported:
          <br />
          {support.label}
        </div>
      </div>
      <input
        ref={inputRef}
        id={`${formId}__input`}
        name={name}
        type="file"
        accept={support.input}
        onChange={(e) => setFormDataValues(e)}
        className="mb-5 d-none"
      />
    </label>
  );
}
