import React from 'react';
import { useRouteLoaderData, useParams } from 'react-router-dom';
import PDFTesting from '../PDF/react-pdf/PDFTesting.tsx';
import IsPdfAllowed from '../PDF/PDFFieldsChecker/IsPdfAllowed.tsx';

export function transformData({ data }, logoBase64) {
  const transformedData = {
    id: data.invoice.id,
    amount: data.invoice.amount,
    customer: data.customer,
    dates: data.invoice.dates,
    items: data.invoice.items,
    notes: data.invoice.notes,
    number: data.invoice.number,
    status: data.invoice.status,
    type: 'invoice',
    user: {
      ...data.user,
      company: {
        ...data.user.company,
        logo: logoBase64,
      },
    },
  };
  return transformedData;
}

export default function InvoiceDocument() {
  const { customerId } = useParams();
  const routeLoaders = customerId ? 'customerInvoiceData' : 'invoiceData';
  const {
    user: { company_logo_base64: logoBase64 },
  } = useRouteLoaderData('root');
  const loaderData = useRouteLoaderData(routeLoaders);
  const data = transformData(loaderData, logoBase64);
  return (
    <IsPdfAllowed template="Complete Your Details to Preview, Print, and Download">
      <div className="pt-lg-5">
        <PDFTesting data={data} portalId="modalBottom" />
      </div>
    </IsPdfAllowed>
  );
}
