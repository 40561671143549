import { redirect } from 'react-router-dom';
import authAPI from '../../API/routesAPI/authAPI';
import { handleResposeAbstraction } from '../../API/fetchUtils';
import { toastHandler } from '../../API/toastHandler';

export const authAction = async ({ request }) => {
  const body = await request.formData();
  const response = await authAPI.login(body);
  const result = await response.json();
  if (response?.ok) {
    localStorage.setItem('token', result.token);
  }

  const url = new URL(request.url);
  const redirectQs = url.searchParams.get('redirect');
  return handleResposeAbstraction({
    response,
    result,
    redirectTo: redirectQs ? `/${redirectQs}` : '/',
  });
};

export const registerAction = async ({ request }) => {
  const body = await request.formData();
  const response = await authAPI.register(body);
  return handleResposeAbstraction({
    response,
    showSuccess: true,
  });
};

export const resetPasswordRequestAction = async ({ request }) => {
  const body = await request.formData();
  const response = await authAPI.resetPasswordRequest(body);
  return handleResposeAbstraction({
    response,
    showSuccess: true,
    redirectTo: '/auth/reset-password-request-success',
  });
};

export const resetPasswordAction = async ({ request }) => {
  const body = await request.formData();
  const response = await authAPI.resetPassword(body);
  return handleResposeAbstraction({
    response,
    showSuccess: true,
    redirectTo: '/auth/reset-password-success',
  });
};

export const requestValidationEmail = async ({ request }) => {
  const body = await request.formData();
  const shouldRedirect = body.get('should_redirect');
  const response = await authAPI.requestValidationEmail(body);
  const result = await response.json();

  if (shouldRedirect && response?.status !== 200) {
    toastHandler({ ok: response?.ok, result });
    return redirect('/auth/verify-email');
  }

  return handleResposeAbstraction({
    response,
    result,
    showSuccess: true,
  });
};
