export function getKeys<T extends object>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}

export const deleteNullKeys = <T extends object>(data: T): void => {
  (Object.keys(data) as (keyof T)[]).forEach((key) => {
    if (data[key] === null) {
      // eslint-disable-next-line no-param-reassign
      delete data[key];
    }
  });
};
