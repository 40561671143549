import React from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import './GeneralHeader.scss';
import DateTime from '../../Helpers/dateTime';
import NavActions from './NavActions';

function GeneralHeader() {
  const result = useLoaderData();

  return (
    <div className="header d-none d-md-grid">
      <Link to="settings" className="client-area">
        {result?.user?.company_logo ? (
          <img
            src={result?.user?.company_logo}
            alt="Company logo"
            style={{ maxWidth: 40, maxHeight: 40, borderRadius: 1000 }}
          />
        ) : (
          <svg
            id="a"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            width={40}
            height={40}
          >
            <path
              fill="lightgrey"
              d="M40,20c0,5.54-2.26,10.56-5.9,14.18-3.62,3.6-8.6,5.82-14.1,5.82s-10.49-2.22-14.1-5.82C2.25,30.56,0,25.54,0,20,0,8.95,8.95,0,20,0s20,8.95,20,20Z"
            />
            <circle fill="grey" cx="20" cy="14.53" r="7.6" />
            <path
              fill="grey"
              d="M34.1,34.18c-3.62,3.6-8.6,5.82-14.1,5.82s-10.49-2.22-14.1-5.82c1.06-6.83,6.96-12.06,14.1-12.06s13.04,5.23,14.1,12.06Z"
            />
          </svg>
        )}

        <div className="ms-2">
          <p className="header__business-name">
            {result?.user?.user_company || 'Specify Company Name'} <br />
          </p>
          <p className="header__user-name">
            <span>User: </span>
            {result?.user?.user_name || result?.alert}
          </p>
        </div>
      </Link>
      <div className="other d-md-flex gap-2">
        <NavActions />

        <div className="time small min-chip d-none d-md-block">
          <DateTime />
        </div>
      </div>
    </div>
  );
}
export default GeneralHeader;
