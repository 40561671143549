const SECTION_OPTIONS = [
  {
    label: 'General',
    value: 'general',
  },
  {
    label: 'Invoices',
    value: 'invoices',
  },
  {
    label: 'Estimates',
    value: 'estimates',
  },
  {
    label: 'Payments',
    value: 'payments',
  },
  {
    label: 'Products & Services',
    value: 'products',
  },
  {
    label: 'Account Settings',
    value: 'settings',
  },
  {
    label: 'Expenses',
    value: 'expenses',
  },
  {
    label: 'Appointments',
    value: 'appointments',
  },
  {
    label: 'Customers',
    value: 'customers',
  },
  {
    label: 'Vendors',
    value: 'vendors',
  },
  {
    label: 'Reports',
    value: 'reports',
  },
  {
    label: 'Checks',
    value: 'checks',
  },
];

export default SECTION_OPTIONS;
