/* eslint react/prop-types: 0 */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import * as Yup from 'yup';
import { useTimezoneSelect, allTimezones } from 'react-timezone-select';
import {
  useFetcher,
  useSubmit,
  Link,
  Outlet,
  useRouteLoaderData,
} from 'react-router-dom';
import { parseISO } from 'date-fns';
import FormWrapper from '../Forms/builders/FormWrapper';
import useFormikTemplate from '../Forms/hooks/useFormikTemplate';
import { isPhoneNumber } from '../Forms/yup/customValidations';
import LBOffCanvas from '../UI/LBOffCanvas';
import useModal from '../UI/LBOffCanvas/useModal';
import CreateForm from '../Forms/CreateForm';
import ImageCropProvider from '../Forms/ImageCrop/ImageCropProvider';
import objectToFormData from '../Forms/helpers/objectToFormData';
import ResendEmailAlert from './ResendEmailAlert.tsx';

function AccountForm({ data }) {
  // Modal - We are in /settings (root)
  const { show, size, handleCloseModal } = useModal('settings');

  // Check if user is eligible for tutorial
  const {
    user: {
      email_verified: isEmailVerified,
      onboarding_helpers: {
        prod_and_serv_count: prodAndServCount,
        customers_count: customersCount,
        show_tutorial: showTutorial,
      },
    },
  } = useRouteLoaderData('root');
  const eligibleForTutorial = prodAndServCount === 0 || customersCount === 0;

  const fetcher = useFetcher();
  const { options: timezoneOptions, parseTimezone } = useTimezoneSelect({
    labelStyle: 'original',
    timezones: allTimezones,
  });

  // Manage file state / update the value in formik
  const [fileData, setFileData] = React.useState({});

  const updateFiles = (fieldName, file) => {
    // We replace the "fakepath" with the real File
    setFileData((prev) => ({ ...prev, [fieldName]: file }));
  };

  const structure = [
    {
      formGroup: 'Login Information',
      groupChildren: [
        {
          name: 'user_email',
          label: 'Email',
          initialValue: data?.user_email || '',
          disabled: true,
          labelAction: <Link to="./change-email-request">Change email</Link>,
        },
        {
          name: 'password',
          label: 'Password',
          initialValue: data?.password_reset_time
            ? `Updated on ${parseISO(data?.password_reset_time).toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short' })}`
            : 'No data available',
          disabled: true,
          labelAction: (
            <Link to="./change-password-request">Change password</Link>
          ),
        },
      ],
    },
    {
      formGroup: 'Account Information',
      groupChildren: [
        {
          name: 'user_name',
          label: 'User name',
          required: true,
          initialValue: data?.user_name || '',
          type: Yup.string().required('Required'),
        },
        {
          name: 'user_company',
          label: 'Company',
          required: true,
          initialValue: data?.user_company || '',
          type: Yup.string().required('Required'),
          maxLength: 40,
        },

        {
          name: 'business_structure',
          label: 'Business structure',
          required: true,
          initialValue: data?.business_structure || '',
          type: Yup.string().required('Required'),
          formType: 'select',
          options: [
            { value: 1, label: 'Limited Liability Company (LLC)' },
            { value: 2, label: 'Sole Proprietor' },
            { value: 3, label: 'Partnership' },
            { value: 4, label: 'S Corporation' },
            { value: 5, label: 'C Corporation' },
            { value: 6, label: 'Doing Business As (DBA)' },
          ],
        },
        {
          name: 'user_phone',
          label: 'Phone',
          formType: 'phone',
          initialValue: data.user_phone || '',
          type: isPhoneNumber,
        },
        {
          name: 'user_mobile',
          label: 'Mobile',
          formType: 'phone',
          initialValue: data.user_mobile || '',
          type: isPhoneNumber,
        },
        {
          name: 'user_web',
          label: 'Web',
          initialValue: data.user_web || '',
          type: Yup.string(),
        },
        {
          name: 'user_ein',
          label: 'EIN',
          initialValue: data?.user_ein || '',
          type: Yup.string(),
        },
        {
          name: 'user_trade',
          label: 'Trade',
          initialValue: data?.user_trade || '',
          formType: 'trades',
          type: Yup.string(),
          labelAction: <Link to="./trades">Manage</Link>,
        },
        {
          name: 'user_timezone',
          label: 'Timezone',
          formType: 'timezone',
          initialValue:
            data?.user_timezone ||
            parseTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
              .value ||
            '',
          type: Yup.string(),
          options: timezoneOptions,
        },
      ],
    },
    {
      formGroup: 'Tutorial',
      showTitle: eligibleForTutorial,
      groupChildren: [
        {
          name: 'toggle_tutorial',
          label: 'Show tutorial',
          initialValue: Boolean(showTutorial) || false,
          type: Yup.bool(),
          formType: 'switch',
          className: eligibleForTutorial ? '' : 'd-none',
        },
      ],
    },
    {
      formGroup: 'Address',
      groupChildren: [
        {
          name: 'autocomplete_address',
          label: 'Search address',
          className: 'input-wide',
          initialValue: '',
          type: Yup.string(),
          formType: 'autocompleteAddress',
          fieldNames: {
            street: 'user_address_1',
            city: 'user_city',
            state: 'user_state',
            zipcode: 'user_zipcode',
            country: 'user_country',
          },
        },
        {
          name: 'user_address_1',
          label: 'Street Address 1',
          initialValue: data?.user_address_1 || '',
          type: Yup.string().required(),
          required: true,
          autocomplete: 'address-line1',
        },
        {
          name: 'user_address_2',
          label: 'Street Address 2',
          initialValue: data?.user_address_2 || '',
          type: Yup.string(),
        },
        {
          name: 'user_city',
          label: 'City',
          initialValue: data?.user_city || '',
          type: Yup.string().required(),
          required: true,
        },
        {
          name: 'user_state',
          label: 'State',
          initialValue: data?.user_state || '',
          type: Yup.string().required(),
          required: true,
        },
        {
          name: 'user_country',
          label: 'Country',
          initialValue: data?.user_country || '',
          type: Yup.string().required(),
          required: true,
        },
        {
          name: 'user_zip',
          label: 'Zip',
          initialValue: data?.user_zip || '',
          type: Yup.string().required(),
          required: true,
        },
      ],
    },
  ];

  const images = [
    {
      formGroup: 'Images',
      groupChildren: [
        {
          name: 'company_logo',
          label: 'Company logo',
          formType: 'imageCrop',
          initialValue: data?.company_logo || '',
          type: Yup.string().required(),
          required: true,
          config: {
            updateFiles,
            fileSize: {
              maxSizeInMB: 10,
              error: 'Max file size is 10MB.',
            },
            dimensions: {
              min: 200,
              max: 8000,
              error: 'The image must be between 200px and 8000px.',
            },
            deleteAction: () => deleteFile('company_logo'),
          },
        },
        {
          name: 'user_photo',
          label: 'User Photo',
          formType: 'imageCrop',
          initialValue: data?.user_photo || '',
          type: Yup.string(),
          config: {
            updateFiles,
            fileSize: {
              maxSizeInMB: 10,
              error: 'Max file size is 10MB.',
            },
            dimensions: {
              min: 200,
              max: 8000,
              error: 'The image must be between 200px and 8000px.',
            },
            deleteAction: () => deleteFile('user_photo'),
          },
        },
      ],
    },
  ];
  const formValues = [...structure, ...images];
  const reactRouterSubmit = useSubmit();
  const submitAction = async (values) => {
    const nextValues = {
      ...values,
      // Why do we use 0 and 1 instead of boolean values in the BE? 👀
      show_tutorial: Boolean(showTutorial),
      ...fileData,
    };
    const myFormData = objectToFormData(nextValues);
    reactRouterSubmit(myFormData, {
      method: 'post',
      encType: 'multipart/form-data',
    });
  };

  const [formik] = useFormikTemplate({
    initial: formValues,
    yupValues: formValues,
    submitAction,
    enableReinitialize: true,
  });

  // FILE COMPONENT STATE AND FUNCTIONS START
  const deleteFile = (name) => {
    if (data[name] === formik.values[name] && data[name] !== null) {
      if (fetcher.state === 'submiting') return;
      fetcher.submit(
        {
          file: name,
        },
        { method: 'post', action: '/settings/delete-settings-files' }
      );
    }
  };

  return (
    <ImageCropProvider>
      {!isEmailVerified && <ResendEmailAlert />}

      <FormWrapper
        id="accountform"
        handleSubmit={formik.handleSubmit}
        isSubmitting={formik.isSubmitting}
        dirty={formik.dirty}
        isValid={formik.isValid}
        portalId="account-submit-container"
      >
        <CreateForm structure={[...structure, ...images]} {...formik} />
      </FormWrapper>
      <div style={{ position: 'sticky', bottom: 0, right: 0 }}>
        <div
          className="sticky__submit-container"
          id="account-submit-container"
        />
      </div>
      <LBOffCanvas show={show} size={size} handleCloseModal={handleCloseModal}>
        <LBOffCanvas.Wrapper>
          <Outlet
            context={{
              tradeId: formik.values.user_trade,
              setFieldValue: formik.setFieldValue,
              fieldKey: 'user_trade',
            }}
          />
        </LBOffCanvas.Wrapper>
      </LBOffCanvas>
    </ImageCropProvider>
  );
}

export default AccountForm;
