import React from 'react';
import { Link, useNavigation, useSubmit } from 'react-router-dom';
import * as Yup from 'yup';
import TextTitle from '../UI/text/TextTitle';
import { resetPasswordRequest } from '../../routes/paths';
import ActionErrorInformation from '../../API/ActionErrorInformation';
import GenericForm from '../Forms/builders/GenericForm';

function Login() {
  const navigation = useNavigation();

  const isTestingEnv = process.env.REACT_APP_IS_TESTING;
  const isDevEnv = process.env.NODE_ENV === 'development';

  const fields = [
    {
      name: 'email',
      label: 'Email',
      initialValue: '',
      placeholder: 'name@email.com',
      type: Yup.string().email().required('Please add your email'),
      formType: 'email',
      required: true,
    },
    {
      name: 'password',
      label: 'Password',
      initialValue: '',
      placeholder: 'password',
      type: Yup.string().strict(true).required('Password is required'),
      formType: 'password',
      autocomplete: 'current-password',
      required: true,
    },
  ];

  const submit = useSubmit();
  const submitAction = (values) => {
    submit(values, { method: 'post' });
  };

  return (
    <>
      <TextTitle>
        <h1>Log In</h1>
        <p className="lb-text__subtitle mt-2">
          Please enter your account details
        </p>
      </TextTitle>

      <ActionErrorInformation />

      <GenericForm
        fields={fields}
        submitAction={submitAction}
        formId="loginForm"
        navigation={navigation.state !== 'idle'}
        submitLabel="Send"
      />

      <div className="d-flex flex-column mt-5 gap-3">
        <Link to={resetPasswordRequest} className="small">
          Forgot Password?
        </Link>
        {!isDevEnv && !isTestingEnv && (
          <div>
            <a
              href="https://leadsboxinvoice.com/subscription/"
              className="small"
            >
              Subscribe now!
            </a>
          </div>
        )}

        {isTestingEnv && (
          <div>
            <p className="d-inline small">TEST ENV ONLY </p>
            <Link className="small" to="./register">
              Create test account
            </Link>
          </div>
        )}
        {isDevEnv && (
          <div>
            <p className="d-inline small">DEV ENV ONLY </p>
            <Link className="small" to="./register">
              Create dev account
            </Link>
          </div>
        )}
      </div>
    </>
  );
}

export default Login;
