import React from 'react';
import {
  Link,
  useFetcher,
  useNavigate,
  useOutletContext,
  useRouteLoaderData,
  useSearchParams,
} from 'react-router-dom';
import Step from '../Step';
import IconButton from '../../UI/IconButton/IconButton';
import { CaratBack } from '../../UI/Icons/Icons';
import ProductForm from './ProductForm';
import { I_QuickStartContext } from '../QuickStart';
import CustomerInfo from '../CustomerStep/CustomerInfo';

function ProdOrServStep() {
  const {
    user: {
      onboarding_helpers: { prod_and_serv_count: prodAndServCount },
    },
  } = useRouteLoaderData('root') as any;
  const { customer, stepChangeHanlder, customerChangeHanlder } =
    useOutletContext() as I_QuickStartContext;
  const navigate = useNavigate();

  // Handle newly created customer
  const [URLSearchParams] = useSearchParams();
  const customerId = URLSearchParams.get('customerId');
  const customerFetcher = useFetcher();

  React.useEffect(() => {
    if (
      customerFetcher.state === 'idle' &&
      !customerFetcher.data &&
      customerId
    ) {
      customerFetcher.load(`/customers/${customerId}`);
    }

    if (customerFetcher?.data && !customer?.id) {
      if (!customerFetcher?.data?.data?.name)
        throw new Error('Error retrieving customer data.');
      const {
        data: {
          name: { fullname: fullName },
          address: { full_address: fullAddress },
          contact: { email },
          id,
        },
      } = customerFetcher?.data || {};

      customerChangeHanlder({
        id,
        fullName,
        fullAddress,
        email,
      });
    }
  }, [customerFetcher]);

  // This prevents the user from staying here if the page is reloaded
  React.useEffect(() => {
    if (!customer?.id && !customerId) {
      navigate('/onboarding/customer');
    }
  }, [customer.id]);

  // Set the step we are currently on
  React.useEffect(() => stepChangeHanlder(2), []);

  const actions = (
    <Step.Actions>
      <IconButton as={Link} to="../customer" text="Back" outline={false}>
        <CaratBack />
      </IconButton>

      <div className="d-flex align-items-center gap-3">
        {prodAndServCount > 0 && <Link to="/onboarding/finish">Skip</Link>}
        <div id="ob-prod-or-service-submit" />
      </div>
    </Step.Actions>
  );

  return (
    <Step actions={actions}>
      <Step.Left>
        <h2 className="mb-4" style={{ fontWeight: 500, fontSize: '1.7rem' }}>
          Create {prodAndServCount ? 'or select' : ''} a product or service
        </h2>
        <p className="my-3">
          To charge your customer, you must first{' '}
          <strong>create a product or service</strong>. This is a simplified
          version of the creation system; for full capabilities, exit the wizard
          to explore the{' '}
          <Link to="/products">products and services module</Link>.
        </p>
        {prodAndServCount > 0 && (
          <p className="my-3">
            We have detected you have{' '}
            {prodAndServCount > 1
              ? 'several products/services'
              : 'one product/service'}{' '}
            already in the system, you can go ahead and{' '}
            <Link to="/onboarding/finish">skip this step</Link> and then use one
            of those in the next step or you could create a new one here.
          </p>
        )}
        {customer?.id && <CustomerInfo customer={customer} abridged />}
      </Step.Left>
      <Step.Right>
        <ProductForm />
      </Step.Right>
    </Step>
  );
}

export default ProdOrServStep;
