/* eslint-disable no-nested-ternary */

import React from 'react';
import { useRouteLoaderData, useParams } from 'react-router-dom';
import PDFTesting from '../PDF/react-pdf/PDFTesting';
import { I_Data } from '../PDF/react-pdf/propTypes';
import IsPdfAllowed from '../PDF/PDFFieldsChecker/IsPdfAllowed';

export function transformData(
  { data }: { data: any },
  logoBase64: string
): I_Data {
  const transformedData = {
    id: data.estimate.id,
    amount: data.estimate.amount,
    customer: data.customer,
    dates: data.estimate.dates,
    items: data.estimate.items,
    notes: data.estimate.notes,
    number: data.estimate.number,
    status: data.estimate.status,
    type: 'estimate',
    user: {
      ...data.user,
      company: {
        ...data.user.company,
        logo: logoBase64,
      },
    },
  };
  return transformedData as I_Data;
}

export default function EstimateDocument({
  printDocument = false,
}: {
  printDocument: boolean;
}) {
  const { customerId } = useParams();
  const routeLoaders = customerId ? 'customerEstimateData' : 'estimateData';
  const {
    user: { company_logo_base64: logoBase64 },
  } = useRouteLoaderData('root') as any;
  const loaderData = useRouteLoaderData(routeLoaders);
  const data = transformData(loaderData as any, logoBase64);
  return (
    <IsPdfAllowed template="Complete Your Details to Preview, Print, and Download">
      <div className={!printDocument ? 'pt-lg-5' : ''}>
        <PDFTesting
          data={data}
          portalId="modalBottom"
          printDocument={printDocument}
        />
      </div>
    </IsPdfAllowed>
  );
}
